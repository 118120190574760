import { ApolloClient, ApolloLink, split, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
// import { ApolloLink, split } from 'apollo-link';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { setContext } from '@apollo/client/link/context';
// import { getMainDefinition } from 'apollo-utilities';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/cache';
import fetch from 'isomorphic-fetch';
import { toast } from 'react-toastify';
import { apiUri } from '../configPublic';
import {
  isJwtExpired,
  setJwt,
  getJwt,
  setApolloClient,
  getRefreshFetchRequest,
} from '../services/auth';
import typePolicies from './typePolicies';

const enableWebsocket = false;

// eslint-disable-next-line no-unused-vars
const authLink = setContext((request, previousContext) => {
  const jwt = getJwt();
  // console.info({ apolloCLientJwt: jwt });
  return jwt != null
    ? {
        headers: { authorization: `Bearer ${jwt}` },
      }
    : null;
});

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'token',
  isTokenValidOrUndefined: () => {
    const jwt = getJwt();
    return jwt == null || !isJwtExpired();
  },
  fetchAccessToken: () => getRefreshFetchRequest(false),
  handleFetch: accessToken => {
    console.info({ accessToken });
    setJwt(accessToken);
  },
  handleError: err => {
    // full control over handling token fetch Error
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);

    // TODO: login prompt
    // your custom action here
    // user.logout();
  },
});

const wsLink =
  typeof window !== 'undefined' && enableWebsocket
    ? new WebSocketLink({
        uri: `wss://${apiUri}/graphql`,
        options: {
          reconnect: true,
        },
      })
    : null;

const httpLink = new HttpLink({
  uri: `https://${apiUri}/graphql`,
  fetch,
  credentials: 'include', // 'same-origin',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.info(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
      if (toast.isActive(message)) toast.update(message);
      else toast.error(message, { toastId: message });
    });

  if (networkError) {
    console.info(`[Network error]: ${networkError}`);
    if (toast.isActive(networkError.message))
      toast.update(networkError.message);
    else toast.error(networkError.message, { toastId: networkError.message });
  }
});

const cleanTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === '__typename' ? undefined : value;
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map(data => data);
});

const terminalLink =
  typeof window !== 'undefined' && enableWebsocket
    ? split(
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query);
          console.info({ kind, operation });
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        httpLink
      )
    : httpLink;

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) =>
  forward(operation).map(data => {
    // data from a previous link
    const time = new Date() - operation.getContext().start;
    console.info(
      `operation ${operation.operationName} took ${time} to complete`
    );
    return data;
  })
);

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: error => {
      console.info({ retryIfError: error });
      return !!error && (error.statusCode < 400 || error.statusCode > 500);
    },
  },
});

const loglink = timeStartLink.concat(logTimeLink);

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    tokenRefreshLink,
    loglink,
    retryLink,
    cleanTypeNameLink,
    errorLink,
    terminalLink,
  ]),
  cache: new InMemoryCache({
    addTypename: true,
    resultCaching: true,
    // possibleTypes: {},
    typePolicies,
  }),
});

setApolloClient(client);

export default client;
