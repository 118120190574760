import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CountdownTimer = ({ expire, onExpire }) => {
  const [currentExpire, setCurrentExpire] = useState(expire);
  let timerId;

  const tick = () => {
    if (currentExpire <= 1) {
      setCurrentExpire(0);
      clearInterval(timerId);
      onExpire();
      return;
    }
    setCurrentExpire(currentExpire - 1);
  };

  useEffect(() => {
    if (currentExpire > 0) timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [currentExpire]);

  return <span>{currentExpire}</span>;
};

CountdownTimer.propTypes = {
  expire: PropTypes.number.isRequired,
  onExpire: PropTypes.func,
};

CountdownTimer.defaultProps = {
  onExpire: null,
};

export default CountdownTimer;
