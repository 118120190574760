import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const FormGroup = styled.div`
  display: flex;
  flex-direction: ${p => (p.inline ? 'row' : 'column')};
  flex-wrap: wrap;
  align-items: ${p => p.inline && 'center'};
  margin-bottom: ${p => p.theme.space * 2}px;

  > * {
    width: ${p => p.inline && 'auto !important'};
  }

  > *:not(:last-child) {
    margin-right: ${p => p.inline && '16px'};
  }
`;

FormGroup.displayName = 'FormGroup';

FormGroup.propTypes = {
  inline: PropTypes.bool,
};

FormGroup.defaultProps = {
  theme: defaultTheme,
  inline: false,
};

export default FormGroup;
