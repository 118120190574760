import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const PanelFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${p => p.theme.space * 6}px;
  padding-right: ${p => p.theme.space * 2}px;
  padding-left: ${p => p.theme.space * 2}px;
  color: ${p => p.theme.panelFooterFg};
  background-color: ${p => p.theme.panelFooterBg};
  border-top: 1px solid ${p => p.theme.panelFooterBorder};
  border-bottom: 2px solid ${p => p.theme.panelFooterBorder};
`;

PanelFooter.displayName = 'PanelFooter';

PanelFooter.defaultProps = {
  theme: defaultTheme,
};

export default PanelFooter;
