import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const Root = styled.label`
  display: inline-flex;
  align-items: center;
  margin-top: ${p => p.theme.space}px;
  margin-right: ${p => p.theme.space * 2}px;
  margin-bottom: ${p => p.theme.space}px;
  cursor: ${p => !p.$isDisabled && 'pointer'};
  pointer-events: ${p => p.$isDisabled && 'none'};
`;

const Container = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: ${p => p.theme.space + 2}px;
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: ${p => p.theme.checkboxControl};
  background-color: ${p => p.theme.checkboxBg};
  box-shadow: inset 0 0 0 1px ${p => p.theme.checkboxBorder};
  transition: box-shadow ${p => p.theme.transitionFast} ease-in-out;

  svg {
    display: inline-block;
    height: 1em;
    font-size: 12px;
    user-select: none;
  }
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;

  &:disabled + ${Icon} {
    color: ${p => p.theme.checkboxControl};
    background-color: ${p => p.theme.checkboxBgDisabled};
    box-shadow: inset 0 0 0 2px ${p => p.theme.checkboxBorderDisabled};
  }

  &:checked:disabled + ${Icon} {
    color: ${p => p.theme.checkboxControlChecked};
    background-color: ${p => p.theme.checkboxBgCheckedDisabled};
    box-shadow: none;
  }

  &:checked:not(:disabled) + ${Icon} {
    color: ${p => p.theme.checkboxControlChecked};
    background-color: ${p => p.theme.checkboxBgChecked};
    box-shadow: none;
  }

  &:focus:not(:disabled) + ${Icon} {
    box-shadow: ${p => `0 0 0 2px ${p.theme.checkboxFocus}`};
  }

  &:focus:not(:disabled):not(:checked) + ${Icon} {
    box-shadow: ${p =>
      `inset 0 0 0 1px ${p.theme.checkboxBorder}, 0 0 0 2px ${p.theme.checkboxFocus}`};
  }
`;

const Label = styled.span`
  color: ${p => (p.$isDisabled ? p.theme.checkboxBorderDisabled : 'inherit')};
  font-size: ${p => p.theme.checkboxFontSize};
  font-weight: ${p => p.theme.checkboxFontWeight};
`;

const Checkbox = forwardRef(
  ({ className, style, children, theme, disabled, ...props }, ref) => (
    <Root className={className} $isDisabled={disabled} style={style}>
      <Container>
        <Input ref={ref} type="checkbox" disabled={disabled} {...props} />
        <Icon>
          <svg
            aria-hidden="true"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            />
          </svg>
        </Icon>
      </Container>
      {children && <Label $isDisabled={disabled}>{children}</Label>}
    </Root>
  ),
);

Checkbox.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  theme: defaultTheme,
  disabled: false,
};

export default Checkbox;
