import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import { useTheme } from '../../utils';
import { customStyles } from '../AutocompleteAsync/AutocompleteAsync';

const createOption = label => ({
  label,
  value: label,
});

const CreatableMultiSelectTextInput = ({
  onChange,
  id,
  value: initialValue,
  isMobile= false,
  mod= 'outline',
  size= 'md',
  borderWidth= 'xs',
  radius= 'sm',
  transition= 'fast',
  shadow= 'md',
  ...props
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState(''); // this variable captures the text user is typing before pressing enter
  const [value, setValue] = useState(
    Array.isArray(initialValue) ? initialValue : [],
  );

  useEffect(() => {
    setValue(Array.isArray(initialValue) ? initialValue : []);
  }, [initialValue]);

  const handleChange = options => {
    const val = [...new Set(options.map(itm => itm.label))];
    setValue(val);
    onChange({ target: { name: id, value: val } });
  };

  const handleInputChange = val => {
    setInputValue(val);
  };

  const handleKeyDown = event => {
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        let newValues = [...value, inputValue.toLowerCase().trim()];
        newValues = [...new Set(newValues)];
        setValue(newValues); // remove duplicates
        onChange({ target: { name: id, value: newValues } });
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      components={{ DropdownIndicator: null }}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={inputValue !== ''}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter..."
      value={value.map(label => createOption(label))}
      theme={theme}
      centerOptions
      styles={customStyles}
      isMobile={isMobile}
      mod={mod}
      size={size}
      borderWidth={borderWidth}
      radius={radius}
      transition={transition}
      shadow={shadow}
      {...props}
    />
  );
};

CreatableMultiSelectTextInput.propTypes = {
  isMobile: PropTypes.bool,
  mod: PropTypes.string,
  size: PropTypes.string,
  borderWidth: PropTypes.string,
  radius: PropTypes.string,
  transition: PropTypes.string,
  shadow: PropTypes.string,
};

export default CreatableMultiSelectTextInput;
