import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteAsync } from '../AutocompleteAsync/AutocompleteAsync';

const AutocompleteAsyncWithReverseLookup = ({
  value = null,
  reverseLookup = null,
  ...props
}) => {
  const [inputValue, setInputValue] = useState();
  const [isLoading, setIsLoaoding] = useState(false);
  useEffect(() => {
    if (typeof reverseLookup === 'function') {
      // if(props.name === "primaryKamId") console.info({value, name: props.name})
      setIsLoaoding(true);
      reverseLookup(value)
        .then((reverseLookupValue) => setInputValue(reverseLookupValue))
        .finally(() => {
          setIsLoaoding(false);
        });
    } else {
      setInputValue(value);
    }
  }, [value]);

  return (
    <AutocompleteAsync value={inputValue} isLoading={isLoading} {...props} />
  );
};

AutocompleteAsyncWithReverseLookup.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  reverseLookup: PropTypes.func,
};

export default AutocompleteAsyncWithReverseLookup;
