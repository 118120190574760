import styled from 'styled-components';
import { fadeIn, spin } from '../../animations';

export const Root = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: ${(p) => p.theme.modal.bgOverlay};
  animation-duration: 75ms;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  outline: 0;
  z-index: 7000;

  @media (max-width: 767px) {
    top: 90px;
    height: calc(100% - 90px);
    border-top: 1px solid #dedee0;
  }

  @media (min-width: 768px) {
    top: 0;
    height: 100%;
    padding: 24px;
  }
`;

export const Dialog = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  pointer-events: none;

  @media (max-width: 767px) {
    align-items: stretch;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: calc(100% - 48px);
    max-width: ${(p) => p.$maxWidth};
    height: calc(100% - 48px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-height: 100%;
  color: ${(p) => p.theme.fg};
  background-color: ${(p) => p.theme.modal.bg};
  background-clip: padding-box;
  outline: 0;
  pointer-events: all;

  @media (max-width: 767px) {
    padding: ${(p) => p.theme.spaces[2]};
  }

  @media (min-width: 768px) {
    padding: 24px;
    border-radius: ${(p) => p.theme.spaces[1]};
  }
`;

export const Title = styled.div`
  display: block;
  width: calc(100% + 48px);
  max-height: 100%;
  margin-top: -16px;
  margin-bottom: 16px;
  margin-left: -24px;
  color: ${(p) => p.theme.fg};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px 48px 16px 24px;
`;

export const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  color: ${(p) => p.theme.fg};
  background-color: transparent;
  font-size: 24px;
  text-align: center;
  border: none;
  transition: color 150ms;
  cursor: pointer;
  z-index: 2;

  &:hover {
    opacity: 0.7;
  }
`;

export const Body = styled.div`
  margin: -${(p) => p.theme.spaces[1]} -${(p) => p.theme.spaces[2]};
  padding: ${(p) => p.theme.spaces[1]} ${(p) => p.theme.spaces[2]};
  overflow: hidden auto;
`;

export const Preloader = styled.div`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(p) => p.theme.bg};
  opacity: 0.5;
  z-index: 1;
`;

export const PreloaderBar = styled.div`
  width: 30px;
  height: 30px;
  border: 3px solid ${(p) => p.theme.fg};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
