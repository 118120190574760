import PropTypes from 'prop-types';
import styled from 'styled-components';
import config, { breakpointKeys } from '../config';

const Row = styled('div')`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: ${(config.gutterWidth / 2) * -1}px;
  margin-left: ${(config.gutterWidth / 2) * -1}px;
  ${p => p.reverse && `flex-direction: row-reverse;`};
  ${p =>
    p.start &&
    config.media[p.start]`justify-content: flex-start; text-align: start;`};
  ${p =>
    p.center &&
    config.media[p.center]`justify-content: center; text-align: center;`};
  ${p =>
    p.end && config.media[p.end]`justify-content: flex-end; text-align: end;`};
  ${p => p.top && config.media[p.top]`align-items: flex-start;`};
  ${p => p.middle && config.media[p.middle]`align-items: center;`};
  ${p => p.bottom && config.media[p.bottom]`align-items: flex-end;`};
  ${p => p.around && config.media[p.around]`justify-content: space-around;`};
  ${p => p.between && config.media[p.between]`justify-content: space-between;`};
`;

Row.displayName = 'Row';

Row.propTypes = {
  reverse: PropTypes.bool,
  start: PropTypes.oneOf(breakpointKeys),
  center: PropTypes.oneOf(breakpointKeys),
  end: PropTypes.oneOf(breakpointKeys),
  top: PropTypes.oneOf(breakpointKeys),
  middle: PropTypes.oneOf(breakpointKeys),
  bottom: PropTypes.oneOf(breakpointKeys),
  around: PropTypes.oneOf(breakpointKeys),
  between: PropTypes.oneOf(breakpointKeys),
};

Row.defaultProps = {
  reverse: false,
  start: null,
  center: null,
  end: null,
  top: null,
  middle: null,
  bottom: null,
  around: null,
  between: null,
};

export default Row;
