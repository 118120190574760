import { createGlobalStyle } from 'styled-components';
import {
  fadeOut,
  slideInLeft,
  slideInRight,
  slideInDown,
  slideInUp,
} from '@uplft/react-ui-kit';

import ProductSansLightWoff from '../fonts/ProductSans-Light.woff';
import ProductSansLightWoff2 from '../fonts/ProductSans-Light.woff2';
import ProductSansRegularWoff from '../fonts/ProductSans-Regular.woff';
import ProductSansRegularWoff2 from '../fonts/ProductSans-Regular.woff2';
import ProductSansMediumWoff from '../fonts/ProductSans-Medium.woff';
import ProductSansMediumWoff2 from '../fonts/ProductSans-Medium.woff2';
import ProductSansBoldWoff from '../fonts/ProductSans-Bold.woff';
import ProductSansBoldWoff2 from '../fonts/ProductSans-Bold.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'ProductSans';
    font-style: normal;
    font-weight: 300;
    src: url(${ProductSansLightWoff2}) format('woff2'), url(${ProductSansLightWoff}) format('woff');
  }
  @font-face {
    font-family: 'ProductSans';
    font-style: normal;
    font-weight: 400;
    src: url(${ProductSansRegularWoff2}) format('woff2'), url(${ProductSansRegularWoff}) format('woff');
  }
  @font-face {
    font-family: 'ProductSans';
    font-style: normal;
    font-weight: 500;
    src: url(${ProductSansMediumWoff2}) format('woff2'), url(${ProductSansMediumWoff}) format('woff');
    ascent-override: 95%;
  }
  @font-face {
    font-family: 'ProductSans';
    font-style: normal;
    font-weight: 700;
    src: url(${ProductSansBoldWoff2}) format('woff2'), url(${ProductSansBoldWoff}) format('woff');
  }

  html,
  body {
    height: 100%;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
    font-size: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    min-width: 375px;
    margin: 0;
    color: ${p => (p.theme.colorScheme === 'light' ? '#202c4c' : '#fff')};
    font-family: ${p => p.theme.fontFamily.sans};
  }

  *,
  *:focus {
    outline: none;
  }

  *::-moz-focus-inner {
    border: 0 none;
  }

  a {
    display: inline-block;
    max-width: 100%;
    color: inherit;
    text-decoration: none;
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3 {
    margin: 0;
    font-weight: 200;
    letter-spacing: 0.02em;
  }

  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 500;
  }

  h1 {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 35px;
    line-height: 1.3333333333333333;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 21px;
    line-height: 1;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;
    line-height: 1.1428571428571428;
    margin-bottom: 8px;
  }

  h5 {
    font-size: 16px;
    line-height: 1.3333333333333333;
    margin-bottom: 16px;
  }

  h6 {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  p,
  blockquote {
    font-size: 14px;
    margin: 0 0 16px;
    line-height: 1.5;
  }

  dl,
  ol,
  ul {
    font-size: 14px;
    margin: 0 0 16px;
    line-height: 1.5;
    padding-left: 16px;
  }

  dd,
  dt,
  li {
    line-height: 1.5;
    margin: 0;
  }

  label > input {
    margin-top: ${p => p.theme.space}px;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button {
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    appearance: none;
  }

  button:hover {
    cursor: pointer;
  }

  hr {
    height: 1px;
    margin: 0;
    padding: 0;
    background-color: ${p => p.theme.borderColor};
    border: 0;
  }

  sup {
    vertical-align: 10%;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: ${p => p.theme.space * 3}px;
    }
  }

  textarea {
    resize: vertical;
  }

  small {
    font-size: 88%;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
    min-height: 100%;
  }

  div[role="group"][tabindex] {
    height: 100%;
    min-height: 100%;
  }

  @media print {
    *,
    *::before,
    *::after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]::after {
      content: ' (' attr(href) ')';
    }

    abbr[title]::after {
      content: ' (' attr(title) ')';
    }

    a[href^='#']::after,
    a[href^='javascript:']::after {
      content: '';
    }

    pre,
    blockquote {
      border: 1px solid ${p => p.theme.borderColor};
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: ${p => p.theme.scrollbarTrack};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${p => p.theme.scrollbarThumb};
    transition: all .3s ease-in-out;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar {
    width: ${p => p.theme.scrollbarWidth};
    height: ${p => p.theme.scrollbarWidth};
  }

  .Toastify__slide-enter {
    &--top-left,
    &--bottom-left {
        animation-name: ${slideInLeft};
    }
    &--top-right,
    &--bottom-right {
        animation-name: ${slideInRight};
    }
    &--top-center {
        animation-name: ${slideInDown};
    }
    &--bottom-center {
        animation-name: ${slideInUp};
    }
  }

  .Toastify__fade-exit {
    &--top-left,
    &--bottom-left,
    &--top-right,
    &--bottom-right,
    &--top-center,
    &--bottom-center {
        animation-name: ${fadeOut};
    }
  }

  .Toastify__toast-body {
    flex-grow: 1;
  }

  .grecaptcha-badge {
    z-index: 10;
  }

  .customMapMarkerLabel {
    background-color: white;
    border: 1px solid white;
    padding: 2px 10px;
  }
`;
