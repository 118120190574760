import { defaultTheme, light, dark } from '@uplft/react-ui-kit';

const colors = {
  white: '#fff',
  green: '#00d3a3',
  red: '#ff0000',
  blue: '#5279f7',
  lightBlue: '#f5f7ff',
  lighterBlue: '#f9f9fb',
  darkBlue: '#202c4c',
  gray: '#858691',
  lightGray: '#fafafc',
  darkGray: '#292b3c',
  darkerGray: '#222433',
  primary: '#5279f7',
};

const variants = {
  ...light.variants,
  primary: {
    ...light.variants.primary,
    500: colors.blue,
  },
};

export const lightTheme = {
  ...defaultTheme,
  ...light,

  modal: {
    fg: 'inherit',
    bg: '#fff',
    bgOverlay: 'rgba(34, 36, 51, 0.4)',
  },
  menuModules: {
    bg: colors.lightBlue,
    bgHover: 'rgba(82, 121, 247, 0.15)',
  },
  search: {
    fg: 'rgba(32, 44, 76, 0.75)',
    bg: 'rgba(32, 44, 76, 0.03)',
    bgActive: colors.white,
    bgResults: colors.lightBlue,
    overlay: 'rgba(34, 36, 51, 0.4)',
    border: 'rgba(82, 121, 247, 0.25)',
    radius: '10px',
    shadow: '0 5px 30px 0 rgba(0, 0, 0, 0.1)',
  },
  layout: {
    bg: {
      mobile: '#f5f5f7',
      desktop: '#fafafc',
    },
  },
  main: {
    fg: colors.darkBlue,
    bg: {
      mobile: '#fff',
      desktop: '#fafafc',
    },
  },
  dropdown: {
    bg: colors.white,
    bgItemActive: colors.lightBlue,
    border: 'rgba(82, 121, 247, 0.25)',
    shadow: '0 5px 30px 0 rgba(0, 0, 0, 0.1)',
  },

  scrollbarWidth: '6px',
  scrollbarTrack: '#ccc',
  scrollbarThumb: '#9ea2aa',
  error: '#ff5b5b',
  tablePreloaderBar: {
    default: light.colors.chillGray[50],
    highlight: light.colors.chillGray[200],
    from: light.colors.chillGray[300],
    to: light.colors.chillGray[600],
  },

  // Redefinition of react-ui-kit variables
  fg: '#202c4c',
  borderColor: '#eae9ee',
  primaryHover: '#30375b',
  primaryLight: '#6da5ff',
  variants,
  primary: colors.blue,
  secondary: '#6c757d',
  textColor: '#000',
  input: {
    ...light.input,
    outline: {
      ...light.input.outline,
      addonBg: '#f3f3f3',
    },
  },
  tag: {
    fg: '#5279f7',
    bg: '#f1f3fe',
  },
};

export const darkTheme = {
  ...defaultTheme,
  ...dark,

  modal: {
    fg: 'inherit',
    bg: colors.darkGray,
    bgOverlay: 'rgba(34, 36, 51, 0.75)',
  },
  menuModules: {
    bg: colors.darkerGray,
    bgHover: '#1f1f27',
  },
  search: {
    fg: 'rgba(255, 255, 255, 0.75)',
    bg: colors.darkGray,
    bgActive: colors.darkGray,
    bgResults: '#212433',
    overlay: 'rgba(34, 36, 51, 0.75)',
    border: 'rgba(255, 255, 255, 0.25)',
    radius: '10px',
    shadow: '0 5px 30px 0 rgba(0, 0, 0, 0.3)',
  },
  layout: {
    bg: {
      mobile: '#222433',
      desktop: '#222433',
    },
  },
  main: {
    fg: colors.white,
    bg: {
      mobile: '#292b3c',
      desktop: '#222433',
    },
  },
  dropdown: {
    bg: colors.darkGray,
    bgItemActive: colors.darkerGray,
    border: 'rgba(255, 255, 255, 0.25)',
    shadow: '0 5px 30px 0 rgba(0, 0, 0, 0.3)',
  },

  scrollbarWidth: '6px',
  scrollbarTrack: '#333',
  scrollbarThumb: '#4d5563',
  error: '#ff9548',
  tablePreloaderBar: {
    default: light.colors.chillGray[600],
    highlight: light.colors.chillGray[400],
    from: light.colors.chillGray[600],
    to: light.colors.chillGray[100],
  },

  // Redefinition of react-ui-kit variables
  fg: '#fff',
  borderColor: colors.gray,
  primaryHover: '#1567b8',
  primaryLight: '#6da5ff',
  variants,
  primary: colors.blue,
  secondary: '#6c757d',
  textColor: colors.white,
  tag: {
    fg: '#5279f7',
    bg: '#272f4f',
  },
};
