import colors from './colors';

const fontFamily = {
  sans:
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  serif: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
  mono:
    'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
};
const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  base: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
};
const fontWeights = {
  hairline: '100',
  thin: '200',
  light: '300',
  normal: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extrabold: '800',
  black: '900',
};
const letterSpacings = {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0em',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
};
const lineHeights = {
  none: 1,
  tight: 1.25,
  snug: 1.375,
  normal: 1.5,
  relaxed: 1.625,
  loose: 2,
  3: '.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
};
const spaces = [
  '4px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px',
  '72px',
  '80px',
  '88px',
  '96px',
];
const sizes = {
  xs: '18px',
  sm: '24px',
  md: '35px',
  lg: '40px',
  xl: '48px',
};
const shadows = {
  xs: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 2px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  xxl: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  inset: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
};
const transitions = {
  fastest: '100ms',
  fast: '200ms',
  medium: '300ms',
  slow: '400ms',
  slowest: '500ms',
};
const variants = {
  primary: colors.blue,
  secondary: colors.gray,
  success: colors.green,
  info: colors.teal,
  warning: colors.amber,
  danger: colors.red,
};

const borderWidth = {
  none: '0',
  xs: '1px',
  sm: '2px',
  md: '4px',
  lg: '6px',
  xl: '8px',
};
const radius = {
  none: '0',
  xs: '2px',
  sm: '4px',
  md: '6px',
  lg: '8px',
  xl: '16px',
  full: '9999px',
};

export const light = {
  colorScheme: 'light',
  fg: '#000000',
  bg: '#ffffff',
  borderColor: '#c6cacc',
  primary: '#3b82f6',

  fontFamily,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  spaces,
  sizes,
  shadows,
  transitions,
  colors,
  variants,
  borderWidth,
  radius,
  ringWidth: '3px',

  badge: {
    borderWidth,
    radius,
  },
  card: {
    fgAccent: '#3b82f6',
    bg: '#f9fafb',
    bgAlt: '#e2e8f0',
    borderColor: '#c6cacc',
  },
  progress: {
    bg: '#d1d5db',
  },
  button: {
    borderWidth,
    radius,
  },
  label: {
    fg: '#3e4345',
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.medium,
  },
  input: {
    borderWidth,
    radius,
    outline: {
      fg: '#586179',
      bg: 'transparent',
      borderColor: '#e2e3e9',
      errorBorderColor: '#ef4444',
      ringColor: '#c6cacc',
      arrowColor: '#586179',
      placeholder: '#c0c2c7',
      addonBg: '#e3e4e5',
    },
    smooth: {
      fg: '#000000',
      bg: '#f1f3f4',
      borderColor: '#f1f3f4',
      errorBorderColor: '#ef4444',
      ringColor: '#c6cacc',
      arrowColor: '#919699',
      placeholder: '#919699',
      addonBg: '#e3e4e5',
    },
  },
  modal: {
    fg: '#000000',
    bg: '#ffffff',
    bgOverlay: 'rgba(34, 36, 51, 0.4)',
  },
  popup: {
    fg: '#000000',
    bg: '#ffffff',
    active: '#e3e4e5',
  },
  table: {
    bg: 'transparent',
    bgAlt: '#f9fafb',
    borderColor: '#c6cacc',
  },
  switch: {
    bg: 'transparent',
    bgChecked: '#3b82f6',
    border: '#919699',
    borderChecked: '#3b82f6',
    control: '#919699',
    controlChecked: '#ffffff',
    ringColor: '#c6cacc',
    width: '44px',
    height: '22px',
  },
};

export const dark = {
  colorScheme: 'dark',
  fg: '#fafafa',
  bg: '#262626',
  borderColor: '#5e6366',
  primary: '#3b82f6',

  fontFamily,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  spaces,
  sizes,
  shadows,
  transitions,
  colors,
  variants,
  borderWidth,
  radius,
  ringWidth: '3px',

  badge: {
    borderWidth,
    radius,
  },
  card: {
    fgAccent: '#3b82f6',
    bg: '#2a2e2f',
    bgAlt: '#3e4345',
    borderColor: '#4d5255',
  },
  progress: {
    bg: '#2a2e2f',
  },
  button: {
    borderWidth,
    radius,
  },
  label: {
    fg: '#d4d4d4',
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.medium,
  },
  input: {
    borderWidth,
    radius,
    outline: {
      fg: '#fff',
      bg: 'transparent',
      borderColor: '#4e515e',
      errorBorderColor: '#ef4444',
      ringColor: '#5e6366',
      arrowColor: '#fff',
      placeholder: '#676c7d',
      addonBg: '#4d5255',
    },
    smooth: {
      fg: '#fafafa',
      bg: '#3e4345',
      borderColor: '#3e4345',
      errorBorderColor: '#ef4444',
      ringColor: '#c6cacc',
      arrowColor: '#919699',
      placeholder: '#919699',
      addonBg: '#4d5255',
    },
  },
  modal: {
    fg: '#fafafa',
    bg: '#3e4345',
    bgOverlay: 'rgba(34, 36, 51, 0.75)',
  },
  popup: {
    fg: '#fafafa',
    bg: '#5e6366',
    active: '#3e4345',
  },
  table: {
    bg: 'transparent',
    bgAlt: '#2a2e2f',
    borderColor: '#4d5255',
  },
  switch: {
    bg: 'transparent',
    bgChecked: '#3b82f6',
    border: '#5e6366',
    borderChecked: '#3b82f6',
    control: '#5e6366',
    controlChecked: '#ffffff',
    ringColor: '#5e6366',
    width: '44px',
    height: '22px',
  },
};
