import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const RadioComponent = ({ className, disabled, children, theme, ...rest }) => (
  <label className={className}>
    <span className="Radio__wrapper">
      <input
        className="Radio__input"
        type="radio"
        disabled={disabled}
        {...rest}
      />
      <span className="Radio__icon">
        <svg
          aria-hidden="true"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
          />
        </svg>
      </span>
    </span>
    {children && <span className="Radio__text">{children}</span>}
  </label>
);

const Radio = styled(RadioComponent)`
  display: inline-flex;
  align-items: center;
  margin-top: ${p => p.theme.space}px;
  margin-right: ${p => p.theme.space * 2}px;
  margin-bottom: ${p => p.theme.space}px;
  cursor: ${p => !p.disabled && 'pointer'};
  pointer-events: ${p => p.disabled && 'none'};

  .Radio__wrapper {
    position: relative;
    flex: 0 0 auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: ${p => p.theme.space + 2}px;
  }

  .Radio__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: inherit;

    &:disabled + .Radio__icon {
      color: ${p => p.theme.radioControl};
      background-color: ${p => p.theme.radioBgDisabled};
      box-shadow: inset 0 0 0 2px ${p => p.theme.radioBorderDisabled};
    }

    &:checked:disabled + .Radio__icon {
      color: ${p => p.theme.radioControlChecked};
      background-color: ${p => p.theme.radioBgCheckedDisabled};
      box-shadow: none;
    }

    &:checked:not(:disabled) + .Radio__icon {
      color: ${p => p.theme.radioControlChecked};
      background-color: ${p => p.theme.radioBgChecked};
      box-shadow: none;
    }

    &:focus + .Radio__icon {
      box-shadow: ${p => `0 0 0 2px ${p.theme.radioFocus}`};
    }
  }

  .Radio__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: ${p => p.theme.radioControl};
    background-color: ${p => p.theme.radioBg};
    box-shadow: inset 0 0 0 1px ${p => p.theme.radioBorder};
    border-radius: 50%;
    transition: box-shadow ${p => p.theme.transitionFast} ease-in-out;

    svg {
      display: inline-block;
      height: 1em;
      font-size: 8px;
      user-select: none;
    }
  }

  .Radio__text {
    font-size: ${p => p.theme.radioFontSize};
    font-weight: ${p => p.theme.radioFontWeight};
  }
`;

Radio.displayName = 'Radio';

Radio.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  theme: defaultTheme,
  disabled: false,
};

export default Radio;
