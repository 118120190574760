import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ring } from '../../utils';

const Root = styled.div`
  display: table;
  border-collapse: separate;
  width: 100%;
  font-size: ${p =>
    (p.size === 'xs' && 10) ||
    (p.size === 'sm' && 12) ||
    (p.size === 'md' && 14) ||
    (p.size === 'lg' && 14) ||
    (p.size === 'xl' && 18)}px;
`;

const StyledInput = styled('input').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['radius'].includes(prop) && defaultValidatorFn(prop),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: ${p => p.theme.sizes[p.size]};
  height: ${p => p.theme.sizes[p.size]};
  line-height: ${p => p.theme.sizes[p.size]};
  padding-right: ${p =>
    p.size === 'xs' ? p.theme.spaces[1] : p.theme.spaces[2]};
  padding-left: ${p =>
    p.size === 'xs' ? p.theme.spaces[1] : p.theme.spaces[2]};
  color: ${p => p.theme.input[p.mod].fg};
  background-color: ${p => p.theme.input[p.mod].bg};
  border-width: ${p => p.theme.input.borderWidth[p.borderWidth]};
  border-style: solid;
  border-color: ${p =>
    p.isInvalid
      ? p.theme.input[p.mod].errorBorderColor
      : p.theme.input[p.mod].borderColor};
  border-radius: ${p => p.theme.input.radius[p.radius]};

  border-bottom-right-radius: ${p => p.suffix && 0};
  border-top-right-radius: ${p => p.suffix && 0};
  border-bottom-left-radius: ${p => p.prefix && 0};
  border-top-left-radius: ${p => p.prefix && 0};

  transition: all ${p => p.theme.transitions[p.transition]};
  white-space: nowrap;
  appearance: none;

  &:not([disabled]):focus {
    box-shadow: ${p =>
      p.ring &&
      ring({
        width: p.theme.ringWidth,
        color: p.theme.input[p.mod].ringColor,
      })};
  }

  &[disabled] {
    opacity: 0.6;
    cursor: auto;
  }

  &[readonly] {
    cursor: default;
    pointer-events: none;
  }

  &::placeholder {
    color: ${p => p.theme.input[p.mod].placeholder};
  }

  /*** iPhone and iOS Form Input Zoom Fixes ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    font-size: 16px;
  }
`;

const Addon = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['radius'].includes(prop) && defaultValidatorFn(prop),
})`
  display: table-cell;
  width: 1%;
  min-width: ${p => p.theme.sizes[p.size]};
  padding-left: ${p => p.theme.spaces[1]};
  padding-right: ${p => p.theme.spaces[1]};
  color: ${p => p.theme.input[p.mod].fg};
  background-color: ${p => p.theme.input[p.mod].addonBg};
  text-align: center;
  line-height: 1;
  border-width: ${p =>
    p.mod === 'outline' ? p.theme.input.borderWidth[p.borderWidth] : 0};
  border-style: solid;
  border-color: ${p => p.theme.input[p.mod].borderColor};
  border-radius: ${p => p.theme.input.radius[p.radius]};
  white-space: nowrap;

  &:first-child {
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &:last-child {
    border-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const Input = forwardRef(
  ({ mod, size, borderWidth, radius, prefix, suffix, ...props }, ref) => {
    return (
      <Root>
        {prefix && (
          <Addon
            mod={mod}
            size={size}
            borderWidth={borderWidth}
            radius={radius}
          >
            {prefix}
          </Addon>
        )}
        <StyledInput
          ref={ref}
          mod={mod}
          size={size}
          borderWidth={borderWidth}
          radius={radius}
          prefix={prefix}
          suffix={suffix}
          {...props}
        />
        {suffix && (
          <Addon
            mod={mod}
            size={size}
            borderWidth={borderWidth}
            radius={radius}
          >
            {suffix}
          </Addon>
        )}
      </Root>
    );
  },
);

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'tel']),
  mod: PropTypes.oneOf(['outline', 'smooth', 'unstyled', PropTypes.string]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', PropTypes.string]),
  borderWidth: PropTypes.oneOf([
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    PropTypes.string,
  ]),
  radius: PropTypes.oneOf([
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'full',
    PropTypes.string,
  ]),
  transition: PropTypes.oneOf([
    'fastest',
    'fast',
    'medium',
    'slow',
    'slowest',
    PropTypes.string,
  ]),
  isInvalid: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.string,
  ring: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  mod: 'outline',
  size: 'md',
  borderWidth: 'xs',
  radius: 'sm',
  transition: 'fast',
  isInvalid: false,
  prefix: null,
  suffix: null,
  ring: false,
};

export default Input;
