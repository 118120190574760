import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../../variables';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: ${p => p.theme.space}px;
  color: ${p => p.theme[`${p.variant}Hover`]};
  background-color: ${p => p.theme[`${p.variant}Light`]};
  border-width: 1px;
  border-style: solid;
  border-color: ${p => p.theme[p.variant]};
  border-radius: ${p => p.theme.radius}px;

  &:not(:last-child) {
    margin-bottom: ${p => p.theme.space * 2}px;
  }

  a {
    color: inherit;
    font-weight: 700;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
`;

const Close = styled.button`
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: ${p => p.theme.space * 2}px;
  padding: 0;
  color: ${p => p.theme[`${p.variant}Hover`]};
  background-color: transparent;
  font-size: 17px;
  border-color: transparent;
  transition: opacity ${p => p.theme.transitionFast};
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme[`${p.variant}Hover`]};
    opacity: 1;
  }
`;

const Notification = ({ onClose, children, ...rest }) => (
  <Root {...rest}>
    <Content>{children}</Content>
    {onClose && (
      <Close type="button" onClick={onClose}>
        <svg
          fill="currentColor"
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" />
        </svg>
      </Close>
    )}
  </Root>
);

Notification.displayName = 'Notification';

Notification.variant = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  info: 'info',
  warning: 'warning',
  danger: 'danger',
};

Notification.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  variant: PropTypes.oneOf(Object.values(Notification.variant)),
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Notification.defaultProps = {
  theme: defaultTheme,
  variant: Notification.variant.primary,
  onClose: null,
};

export default Notification;
