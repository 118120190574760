import { css } from 'styled-components';

export const breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

function makeMedia(media) {
  return (...args) => css`
    @media ${media} {
      ${css(...args)};
    }
  `;
}

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1600,
};

const config = {
  gridSize: 12,
  gutterWidth: 30,
  outerMargin: 60,
  container: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1600,
  },
  breakpoints,
  media: Object.keys(breakpoints).reduce(
    (media, breakpoint) => ({
      ...media,
      [breakpoint]: makeMedia(
        [
          breakpoint !== 0 && `(min-width: ${breakpoints[breakpoint]}px)`,
        ].filter(Boolean),
      ),
    }),
    {},
  ),
};

export default config;
