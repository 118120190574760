// Space
export const space = 8;

// Radius
export const radius = 4;

// Transitions
export const transitionFast = '200ms';
export const transitionMid = '350ms';
export const transitionSlow = '500ms';

// Breakpoints
export const xsMax = 'max-width: 767px';
export const smMin = 'min-width: 768px';
export const smMax = 'max-width: 1023px';
export const mdMin = 'min-width: 1024px';
export const mdMax = 'max-width: 1439px';
export const lgMin = 'min-width: 1440px';

// Colors
export const black = '#000';
export const white = '#fff';

export const gray100 = '#f8f9fa';
export const gray200 = '#e9ecef';
export const gray300 = '#dee2e6';
export const gray400 = '#ced4da';
export const gray500 = '#adb5bd';
export const gray600 = '#6c757d';
export const gray700 = '#495057';
export const gray800 = '#343a40';
export const gray900 = '#212529';

export const brand1 = '#a7b1c2';
export const brand2 = '#2f4050';
export const brand3 = '#293846';

export const primary = gray600;
export const secondary = '#1ab394';
export const success = '#3498db';
export const info = '#23c6c8';
export const warning = '#f1c40f';
export const danger = '#e74c3c';

export const primaryHover = '#60686f';
export const successHover = '#2e8dcf';
export const infoHover = '#32b0b1';
export const warningHover = '#d8b028';
export const dangerHover = '#e03a2c';

export const primaryLight = '#d7dadb';
export const secondaryLight = '#cae9e1';
export const successLight = '#cce3f4';
export const infoLight = '#cbefef';
export const warningLight = '#faeec9';
export const dangerLight = '#f7d0cd';

export const uiTextColor = white;
export const textColor = gray600;
export const bgColor = gray100;
export const borderColor = gray200;

export const dropdownBg = white;
export const dropdownBgHover = white;
export const fileInputBg = white;
export const inputBg = white;

// Checkbox
export const checkboxBg = white;
export const checkboxBgDisabled = white;
export const checkboxBgChecked = '#60686f';
export const checkboxBgCheckedDisabled = '#d7dadc';
export const checkboxBorder = '#60686f';
export const checkboxBorderDisabled = '#d7dadc';
export const checkboxControl = 'transparent';
export const checkboxControlChecked = white;
export const checkboxFocus = 'rgba(108, 117, 125, 0.25)';
export const checkboxFontSize = '14px';
export const checkboxFontWeight = 500;

// Radio
export const radioBg = white;
export const radioBgDisabled = white;
export const radioBgChecked = '#60686f';
export const radioBgCheckedDisabled = '#d7dadc';
export const radioBorder = '#60686f';
export const radioBorderDisabled = '#d7dadc';
export const radioControl = 'transparent';
export const radioControlChecked = white;
export const radioFocus = 'rgba(108, 117, 125, 0.25)';
export const radioFontSize = '14px';
export const radioFontWeight = 500;

// Panel
export const panelFg = gray600;
export const panelBg = 'transparent';

// PanelHeader
export const panelHeaderFg = gray600;
export const panelHeaderBg = 'transparent';
export const panelHeaderBorder = gray200;

// PanelBody
export const panelBodyFg = gray600;
export const panelBodyBg = 'transparent';
export const panelBodyBorder = gray200;

// PanelFooter
export const panelFooterFg = gray600;
export const panelFooterBg = 'transparent';
export const panelFooterBorder = gray200;

// Modal
export const modalOverlayBg = 'rgba(86, 96, 117, 0.7)';
export const modalBg = white;
export const modalRadius = 4;

// ModalClose
export const modalCloseFg = '#60686f';

// ModalHeader
export const modalHeaderFg = gray600;
export const modalHeaderBg = 'transparent';
export const modalHeaderBorder = gray200;

// ModalBody
export const modalBodyFg = gray600;
export const modalBodyBg = gray100;

// ModalFooter
export const modalFooterFg = gray600;
export const modalFooterBg = 'transparent';
export const modalFooterBorder = gray200;
