class EventChannel {
  constructor(name) {
    this.name = name;
    this.callbacks = [];
  }

  registerCallback = callback => {
    this.callbacks.push(callback);
  };

  removeCallback = callback => {
    this.callbacks = this.callbacks.filter(
      cb => cb.toString() !== callback.toString()
    );
  };
}

export default EventChannel;
