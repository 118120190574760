// https://github.com/bl00mber/react-phone-input-2

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import reduce from 'lodash/reduce';
import startsWith from 'lodash/startsWith';

import { cx } from '../../utils';

import CountryData from './CountryData';

import {
  Root,
  Input,
  ButtonContainer,
  SelectedFlag,
  DropdownList,
  DropdownItemDivider,
  DropdownItem,
  CountryName,
  DialCode,
  Arrow,
  Flag,
} from './Phone.style';

class Phone extends Component {
  guessSelectedCountry = memoize(
    (inputNumber, country, onlyCountries, hiddenAreaCodes) => {
      // if enableAreaCodes == false, try to search in hidden area codes to detect area code correctly
      // then search and insert main country which has this area code
      // https://github.com/bl00mber/react-phone-input-2/issues/201
      const { enableAreaCodes } = this.props;
      if (enableAreaCodes === false) {
        let mainCode;
        hiddenAreaCodes.some(country => {
          if (startsWith(inputNumber, country.dialCode)) {
            onlyCountries.some(o => {
              if (country.iso2 === o.iso2 && o.mainCode) {
                mainCode = o;
                return true;
              }
            });
            return true;
          }
        });
        if (mainCode) return mainCode;
      }

      const secondBestGuess = onlyCountries.find(o => o.iso2 == country);
      if (inputNumber.trim() === '') return secondBestGuess;

      const bestGuess = onlyCountries.reduce(
        (selectedCountry, country) => {
          if (startsWith(inputNumber, country.dialCode)) {
            if (country.dialCode.length > selectedCountry.dialCode.length) {
              return country;
            }
            if (
              country.dialCode.length === selectedCountry.dialCode.length &&
              country.priority < selectedCountry.priority
            ) {
              return country;
            }
          }
          return selectedCountry;
        },
        { dialCode: '', priority: 10001 },
        this,
      );

      if (!bestGuess.name) return secondBestGuess;
      return bestGuess;
    },
  );

  constructor(props) {
    super(props);
    const {
      onlyCountries,
      preferredCountries,
      hiddenAreaCodes,
    } = new CountryData(
      props.enableAreaCodes,
      props.enableTerritories,
      props.regions,
      props.onlyCountries,
      props.preferredCountries,
      props.excludeCountries,
      props.preserveOrder,
      props.masks,
      props.priority,
      props.areaCodes,
      props.localization,
      props.prefix,
      props.defaultMask,
      props.alwaysDefaultMask,
    );

    const inputNumber = props.value ? props.value.replace(/\D/g, '') : '';

    let countryGuess;
    if (props.disableInitialCountryGuess) {
      countryGuess = 0;
    } else if (inputNumber.length > 1) {
      // Country detect by phone
      countryGuess =
        this.guessSelectedCountry(
          inputNumber.substring(0, 6),
          props.country,
          onlyCountries,
          hiddenAreaCodes,
        ) || 0;
    } else if (props.country) {
      // Default country
      countryGuess = onlyCountries.find(o => o.iso2 == props.country) || 0;
    } else {
      // Empty params
      countryGuess = 0;
    }

    const dialCode =
      inputNumber.length < 2 &&
      countryGuess &&
      !startsWith(inputNumber, countryGuess.dialCode)
        ? countryGuess.dialCode
        : '';

    const formattedNumber =
      inputNumber === '' && countryGuess === 0
        ? ''
        : this.formatNumber(
            (props.disableCountryCode ? '' : dialCode) + inputNumber,
            countryGuess.name ? countryGuess : undefined,
          );

    const highlightCountryIndex = onlyCountries.findIndex(
      o => o == countryGuess,
    );

    this.state = {
      showDropdown: props.showDropdown,
      formattedNumber,
      onlyCountries,
      preferredCountries,
      hiddenAreaCodes,
      selectedCountry: countryGuess,
      highlightCountryIndex,
      queryString: '',
      freezeSelection: false,
      debouncedQueryStingSearcher: debounce(this.searchCountry, 250),
      searchValue: '',
    };
  }

  componentDidMount() {
    const { enableClickOutside } = this.props;
    if (document.addEventListener && enableClickOutside) {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { country, value } = this.props;
    if (prevProps.country !== country) {
      this.updateCountry(country);
    } else if (prevProps.value !== value) {
      this.updateFormattedNumber(value);
    }
  }

  componentWillUnmount() {
    const { enableClickOutside } = this.props;
    if (document.removeEventListener && enableClickOutside) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  getProbableCandidate = memoize(queryString => {
    if (!queryString || queryString.length === 0) return null;
    // don't include the preferred countries in search
    const { onlyCountries } = this.state;
    const probableCountries = onlyCountries.filter(country => {
      return startsWith(country.name.toLowerCase(), queryString.toLowerCase());
    }, this);
    return probableCountries[0];
  });

  // Hooks for updated props
  updateCountry = country => {
    const { onlyCountries } = this.state;
    const { disableCountryCode } = this.props;
    let newSelectedCountry;
    if (country.indexOf(0) >= '0' && country.indexOf(0) <= '9') {
      // digit
      newSelectedCountry = onlyCountries.find(o => o.dialCode == +country);
    } else {
      newSelectedCountry = onlyCountries.find(o => o.iso2 == country);
    }
    if (newSelectedCountry && newSelectedCountry.dialCode) {
      this.setState({
        selectedCountry: newSelectedCountry,
        formattedNumber: disableCountryCode
          ? ''
          : this.formatNumber(newSelectedCountry.dialCode, newSelectedCountry),
      });
    }
  };

  // View methods
  scrollTo = (country, middle) => {
    if (!country) return;
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    const { enableSearch } = this.props;

    const containerHeight = container.offsetHeight;
    const containerOffset = container.getBoundingClientRect();
    const containerTop = containerOffset.top + document.body.scrollTop;
    const containerBottom = containerTop + containerHeight;

    const element = country;
    const elementOffset = element.getBoundingClientRect();

    const elementHeight = element.offsetHeight;
    const elementTop = elementOffset.top + document.body.scrollTop;
    const elementBottom = elementTop + elementHeight;

    let newScrollTop = elementTop - containerTop + container.scrollTop;
    const middleOffset = containerHeight / 2 - elementHeight / 2;

    if (
      enableSearch ? elementTop < containerTop + 32 : elementTop < containerTop
    ) {
      // scroll up
      if (middle) newScrollTop -= middleOffset;
      container.scrollTop = newScrollTop;
    } else if (elementBottom > containerBottom) {
      // scroll down
      if (middle) newScrollTop += middleOffset;
      const heightDifference = containerHeight - elementHeight;
      container.scrollTop = newScrollTop - heightDifference;
    }
  };

  scrollToTop = () => {
    const container = this.dropdownRef;
    if (!container || !document.body) return;
    container.scrollTop = 0;
  };

  formatNumber = (text, country) => {
    if (!country) return text;

    const { format } = country;
    const {
      disableCountryCode,
      enableAreaCodeStretch,
      enableLongNumbers,
      autoFormat,
    } = this.props;

    let pattern;
    if (disableCountryCode) {
      pattern = format.split(' ');
      pattern.shift();
      pattern = pattern.join(' ');
    } else if (enableAreaCodeStretch && country.isAreaCode) {
      pattern = format.split(' ');
      pattern[1] = pattern[1].replace(
        /\.+/,
        ''.padEnd(country.areaCodeLength, '.'),
      );
      pattern = pattern.join(' ');
    } else {
      pattern = format;
    }

    if (!text || text.length === 0) {
      return disableCountryCode ? '' : this.props.prefix;
    }

    // for all strings with length less than 3, just return it (1, 2 etc.)
    // also return the same text if the selected country has no fixed format
    if ((text && text.length < 2) || !pattern || !autoFormat) {
      return disableCountryCode ? text : this.props.prefix + text;
    }

    const formattedObject = reduce(
      pattern,
      (acc, character) => {
        if (acc.remainingText.length === 0) {
          return acc;
        }

        if (character !== '.') {
          return {
            formattedText: acc.formattedText + character,
            remainingText: acc.remainingText,
          };
        }

        const [head, ...tail] = acc.remainingText;

        return {
          formattedText: acc.formattedText + head,
          remainingText: tail,
        };
      },
      {
        formattedText: '',
        remainingText: text.split(''),
      },
    );

    let formattedNumber;
    if (enableLongNumbers) {
      formattedNumber =
        formattedObject.formattedText + formattedObject.remainingText.join('');
    } else {
      formattedNumber = formattedObject.formattedText;
    }

    // Always close brackets
    if (formattedNumber.includes('(') && !formattedNumber.includes(')'))
      formattedNumber += ')';
    return formattedNumber;
  };

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.numberInputRef;
    input.focus();
    let len = input.value.length;
    if (input.value.charAt(len - 1) === ')') len -= 1;
    input.setSelectionRange(len, len);
  };

  getElement = index => {
    return this[`flag_no_${index}`];
  };

  // return country data from state
  getCountryData = () => {
    const { selectedCountry } = this.state;
    if (!selectedCountry) return {};
    return {
      name: selectedCountry.name || '',
      dialCode: selectedCountry.dialCode || '',
      countryCode: selectedCountry.iso2 || '',
      format: selectedCountry.format || '',
    };
  };

  handleFlagDropdownClick = e => {
    e.preventDefault();
    const {
      showDropdown,
      onlyCountries,
      preferredCountries,
      selectedCountry,
      highlightCountryIndex,
    } = this.state;
    const { disabled } = this.props;
    if (!showDropdown && disabled) return;
    const allCountries = preferredCountries.concat(onlyCountries);

    const newHighlightCountryIndex = allCountries.findIndex(
      o =>
        o.dialCode === selectedCountry.dialCode &&
        o.iso2 === selectedCountry.iso2,
    );

    this.setState(
      {
        showDropdown: !showDropdown,
        highlightCountryIndex: newHighlightCountryIndex,
      },
      () => {
        if (showDropdown) {
          this.scrollTo(this.getElement(highlightCountryIndex));
        }
      },
    );
  };

  handleInput = e => {
    const { value } = e.target;
    const {
      prefix,
      onChange,
      disableCountryCode,
      countryCodeEditable,
      enableLongNumbers,
      disableCountryGuess,
      country,
    } = this.props;
    const {
      onlyCountries,
      selectedCountry,
      hiddenAreaCodes,
      formattedNumber,
      freezeSelection,
    } = this.state;

    let newFormattedNumber = disableCountryCode ? '' : prefix;
    let newSelectedCountry = selectedCountry;
    let newFreezeSelection = freezeSelection;

    if (!countryCodeEditable) {
      const mainCode = newSelectedCountry.hasAreaCodes
        ? onlyCountries.find(
            o => o.iso2 === newSelectedCountry.iso2 && o.mainCode,
          ).dialCode
        : newSelectedCountry.dialCode;

      const updatedInput = prefix + mainCode;
      if (value.slice(0, updatedInput.length) !== updatedInput) return;
    }

    if (value === prefix) {
      // we should handle change when we delete the last digit
      if (onChange) onChange('', this.getCountryData(), e, '');
      return this.setState({ formattedNumber: '' });
    }

    // Does exceed default 15 digit phone number limit
    if (value.replace(/\D/g, '').length > 15) {
      if (enableLongNumbers === false) return;
      if (typeof enableLongNumbers === 'number') {
        if (value.replace(/\D/g, '').length > enableLongNumbers) return;
      }
    }

    // if the input is the same as before, must be some special key like enter etc.
    if (value === formattedNumber) return;

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    if (onChange) e.persist();

    if (value.length > 0) {
      // before entering the number in new format, lets check if the dial code now matches some other country
      const inputNumber = value.replace(/\D/g, '');

      // we don't need to send the whole number to guess the country... only the first 6 characters are enough
      // the guess country function can then use memoization much more effectively since the set of input it
      // gets has drastically reduced
      if (
        !freezeSelection ||
        selectedCountry.dialCode.length > inputNumber.length
      ) {
        if (disableCountryGuess) {
          newSelectedCountry = selectedCountry;
        } else {
          newSelectedCountry =
            this.guessSelectedCountry(
              inputNumber.substring(0, 6),
              country,
              onlyCountries,
              hiddenAreaCodes,
            ) || selectedCountry;
        }
        newFreezeSelection = false;
      }
      newFormattedNumber = this.formatNumber(inputNumber, newSelectedCountry);
      newSelectedCountry = newSelectedCountry.dialCode
        ? newSelectedCountry
        : selectedCountry;
    }

    let caretPosition = e.target.selectionStart;
    const oldFormattedText = formattedNumber;
    const diff = newFormattedNumber.length - oldFormattedText.length;

    this.setState(
      {
        formattedNumber: newFormattedNumber,
        freezeSelection: newFreezeSelection,
        selectedCountry: newSelectedCountry,
      },
      () => {
        if (diff > 0) caretPosition -= diff;

        const lastChar = newFormattedNumber.charAt(
          newFormattedNumber.length - 1,
        );

        if (lastChar === ')') {
          this.numberInputRef.setSelectionRange(
            newFormattedNumber.length - 1,
            newFormattedNumber.length - 1,
          );
        } else if (
          caretPosition > 0 &&
          oldFormattedText.length >= newFormattedNumber.length
        ) {
          this.numberInputRef.setSelectionRange(caretPosition, caretPosition);
        }

        if (onChange)
          onChange(
            newFormattedNumber.replace(/[^0-9]+/g, ''),
            this.getCountryData(),
            e,
            newFormattedNumber,
          );
      },
    );
    if (value.length < 2) setTimeout(this.cursorToEnd, 0);
  };

  handleInputClick = e => {
    const { onClick } = this.props;
    this.setState({ showDropdown: false });
    if (onClick) onClick(e, this.getCountryData());
  };

  handleDoubleClick = e => {
    const len = e.target.value.length;
    e.target.setSelectionRange(0, len);
  };

  handleFlagItemClick = (country, e) => {
    const { selectedCountry, onlyCountries, formattedNumber } = this.state;
    const { onChange } = this.props;
    const currentSelectedCountry = selectedCountry;
    const newSelectedCountry = onlyCountries.find(o => o === country);
    if (!newSelectedCountry) return;

    const unformattedNumber = formattedNumber
      .replace(' ', '')
      .replace('(', '')
      .replace(')', '')
      .replace('-', '');
    const newNumber =
      unformattedNumber.length > 1
        ? unformattedNumber.replace(
            currentSelectedCountry.dialCode,
            newSelectedCountry.dialCode,
          )
        : newSelectedCountry.dialCode;
    const newFormattedNumber = this.formatNumber(
      newNumber.replace(/\D/g, ''),
      newSelectedCountry,
    );

    this.setState(
      {
        showDropdown: false,
        selectedCountry: newSelectedCountry,
        freezeSelection: true,
        formattedNumber: newFormattedNumber,
      },
      () => {
        this.cursorToEnd();
        if (onChange)
          onChange(
            newFormattedNumber.replace(/[^0-9]+/g, ''),
            this.getCountryData(),
            e,
            newFormattedNumber,
          );
      },
    );
  };

  handleInputFocus = e => {
    // if the input is blank, insert dial code of the selected country
    const { prefix, disableCountryCode, jumpCursorToEnd, onFocus } = this.props;
    const { selectedCountry } = this.state;
    if (this.numberInputRef) {
      if (
        this.numberInputRef.value === prefix &&
        selectedCountry &&
        !disableCountryCode
      ) {
        this.setState(
          {
            formattedNumber: prefix + selectedCountry.dialCode,
          },
          () => {
            if (jumpCursorToEnd) setTimeout(this.cursorToEnd, 0);
          },
        );
      }
    }

    this.setState({ placeholder: '' });

    if (onFocus) onFocus(e, this.getCountryData());
    if (jumpCursorToEnd) setTimeout(this.cursorToEnd, 0);
  };

  handleInputBlur = e => {
    const { onBlur, placeholder } = this.props;
    if (!e.target.value) this.setState({ placeholder });
    if (onBlur) onBlur(e, this.getCountryData());
  };

  handleInputCopy = e => {
    const { copyNumbersOnly } = this.props;
    if (!copyNumbersOnly) return;
    const text = window
      .getSelection()
      .toString()
      .replace(/[^0-9]+/g, '');
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
  };

  getHighlightCountryIndex = direction => {
    // had to write own function because underscore does not have findIndex. lodash has it
    const {
      highlightCountryIndex,
      onlyCountries,
      preferredCountries,
    } = this.state;
    const { enableSearch } = this.props;
    const newHighlightCountryIndex = highlightCountryIndex + direction;

    if (
      newHighlightCountryIndex < 0 ||
      newHighlightCountryIndex >=
        onlyCountries.length + preferredCountries.length
    ) {
      return newHighlightCountryIndex - direction;
    }

    if (
      enableSearch &&
      newHighlightCountryIndex > this.getSearchFilteredCountries().length
    ) {
      return 0; // select first country
    }

    return newHighlightCountryIndex;
  };

  searchCountry = () => {
    const { queryString, onlyCountries, preferredCountries } = this.state;
    const probableCandidate =
      this.getProbableCandidate(queryString) || onlyCountries[0];
    const probableCandidateIndex =
      onlyCountries.findIndex(o => o === probableCandidate) +
      preferredCountries.length;

    this.scrollTo(this.getElement(probableCandidateIndex), true);

    this.setState({
      queryString: '',
      highlightCountryIndex: probableCandidateIndex,
    });
  };

  handleKeydown = e => {
    const { keys } = this.props;
    const {
      target: { className },
    } = e;

    if (
      className.includes('selected-flag') &&
      e.which === keys.ENTER &&
      !this.state.showDropdown
    )
      return this.handleFlagDropdownClick(e);
    if (
      className.includes('form-control') &&
      (e.which === keys.ENTER || e.which === keys.ESC)
    )
      return e.target.blur();

    if (!this.state.showDropdown || this.props.disabled) return;
    if (className.includes('search-box')) {
      if (
        e.which !== keys.UP &&
        e.which !== keys.DOWN &&
        e.which !== keys.ENTER
      ) {
        if (e.which === keys.ESC && e.target.value === '') {
          // do nothing // if search field is empty, pass event (close dropdown)
        } else {
          return; // don't process other events coming from the search field
        }
      }
    }

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const moveHighlight = direction => {
      this.setState(
        {
          highlightCountryIndex: this.getHighlightCountryIndex(direction),
        },
        () => {
          this.scrollTo(
            this.getElement(this.state.highlightCountryIndex),
            true,
          );
        },
      );
    };

    switch (e.which) {
      case keys.DOWN:
        moveHighlight(1);
        break;
      case keys.UP:
        moveHighlight(-1);
        break;
      case keys.ENTER:
        if (this.props.enableSearch) {
          this.handleFlagItemClick(
            this.getSearchFilteredCountries()[
              this.state.highlightCountryIndex
            ] || this.getSearchFilteredCountries()[0],
            e,
          );
        } else {
          this.handleFlagItemClick(
            [...this.state.preferredCountries, ...this.state.onlyCountries][
              this.state.highlightCountryIndex
            ],
            e,
          );
        }
        break;
      case keys.ESC:
      case keys.TAB:
        this.setState(
          {
            showDropdown: false,
          },
          this.cursorToEnd,
        );
        break;
      default:
        if (
          (e.which >= keys.A && e.which <= keys.Z) ||
          e.which === keys.SPACE
        ) {
          this.setState(
            {
              queryString:
                this.state.queryString + String.fromCharCode(e.which),
            },
            this.state.debouncedQueryStingSearcher,
          );
        }
    }
  };

  handleInputKeyDown = e => {
    const { keys, onEnterKeyPress, onKeyDown } = this.props;
    if (e.which === keys.ENTER) {
      if (onEnterKeyPress) onEnterKeyPress(e);
    }
    if (onKeyDown) onKeyDown(e);
  };

  handleClickOutside = e => {
    if (this.dropdownRef && !this.dropdownContainerRef.contains(e.target)) {
      this.state.showDropdown && this.setState({ showDropdown: false });
    }
  };

  handleSearchChange = e => {
    const {
      currentTarget: { value: searchValue },
    } = e;
    const { preferredCountries, selectedCountry } = this.state;
    let highlightCountryIndex = 0;

    if (searchValue === '' && selectedCountry) {
      const { onlyCountries } = this.state;
      highlightCountryIndex = preferredCountries
        .concat(onlyCountries)
        .findIndex(o => o == selectedCountry);
      // wait asynchronous search results re-render, then scroll
      setTimeout(
        () => this.scrollTo(this.getElement(highlightCountryIndex)),
        100,
      );
    }
    this.setState({ searchValue, highlightCountryIndex });
  };

  getDropdownCountryName = country => {
    return country.localName || country.name;
  };

  getSearchFilteredCountries = () => {
    const { preferredCountries, onlyCountries, searchValue } = this.state;
    const { enableSearch } = this.props;
    const allCountries = preferredCountries.concat(onlyCountries);
    const sanitizedSearchValue = searchValue.trim().toLowerCase();
    if (enableSearch && sanitizedSearchValue) {
      // [...new Set()] to get rid of duplicates
      // firstly search by iso2 code
      if (/^\d+$/.test(sanitizedSearchValue)) {
        // contains digits only
        // values wrapped in ${} to prevent undefined
        return allCountries.filter(({ dialCode }) =>
          [`${dialCode}`].some(field =>
            field.toLowerCase().includes(sanitizedSearchValue),
          ),
        );
      }
      const iso2countries = allCountries.filter(({ iso2 }) =>
        [`${iso2}`].some(field =>
          field.toLowerCase().includes(sanitizedSearchValue),
        ),
      );
      // || '' - is a fix to prevent search of 'undefined' strings
      // Since all the other values shouldn't be undefined, this fix was accepte
      // but the structure do not looks very good
      const searchedCountries = allCountries.filter(
        ({ name, localName, iso2 }) =>
          [`${name}`, `${localName || ''}`].some(field =>
            field.toLowerCase().includes(sanitizedSearchValue),
          ),
      );
      this.scrollToTop();
      return [...new Set([].concat(iso2countries, searchedCountries))];
    }
    return allCountries;
  };

  getCountryDropdownList = () => {
    const {
      preferredCountries,
      highlightCountryIndex,
      searchValue,
    } = this.state;
    const { disableDropdown, prefix } = this.props;
    const {
      enableSearch,
      searchNotFound,
      disableSearchIcon,
      searchPlaceholder,
      autocompleteSearch,
      shadow,
    } = this.props;

    const searchedCountries = this.getSearchFilteredCountries();

    const countryDropdownList = searchedCountries.map((country, index) => {
      return (
        <DropdownItem
          ref={el => (this[`flag_no_${index}`] = el)}
          key={`flag_no_${index}`}
          data-flag-key={`flag_no_${index}`}
          className={cx({
            preferred: country.iso2 === 'us' || country.iso2 === 'gb',
            active: country.iso2 === 'us',
            highlight: highlightCountryIndex === index,
          })}
          data-dial-code="1"
          tabIndex={disableDropdown ? '-1' : '0'}
          data-country-code={country.iso2}
          onClick={e => this.handleFlagItemClick(country, e)}
          role="option"
          // {...(highlightCountryIndex === index ? { 'aria-selected': true } : {})}
        >
          <Flag className={country.iso2} />
          <CountryName>{this.getDropdownCountryName(country)}</CountryName>
          <DialCode>
            {country.format
              ? this.formatNumber(country.dialCode, country)
              : prefix + country.dialCode}
          </DialCode>
        </DropdownItem>
      );
    });

    const dashedLi = <DropdownItemDivider key="dashes" />;
    // let's insert a dashed line in between preffered countries and the rest
    preferredCountries.length > 0 &&
      (!enableSearch || (enableSearch && !searchValue.trim())) &&
      countryDropdownList.splice(preferredCountries.length, 0, dashedLi);

    return (
      <DropdownList
        ref={el => {
          !enableSearch && el && el.focus();
          return (this.dropdownRef = el);
        }}
        role="listbox"
        tabIndex="0"
        shadow={shadow}
      >
        {enableSearch && (
          <li
            className={cx({
              search: true,
            })}
          >
            {!disableSearchIcon && (
              <span
                className={cx({
                  'search-emoji': true,
                })}
                role="img"
                aria-label="Magnifying glass"
              >
                &#128270;
              </span>
            )}
            <input
              className={cx({
                'search-box': true,
              })}
              type="search"
              placeholder={searchPlaceholder}
              autoFocus
              autoComplete={autocompleteSearch ? 'on' : 'off'}
              value={searchValue}
              onChange={this.handleSearchChange}
            />
          </li>
        )}
        {countryDropdownList.length > 0 ? (
          countryDropdownList
        ) : (
          <li className="no-entries-message">
            <span>{searchNotFound}</span>
          </li>
        )}
      </DropdownList>
    );
  };

  updateFormattedNumber(value) {
    if (value === null)
      return this.setState({ selectedCountry: 0, formattedNumber: '' });

    const { onlyCountries, selectedCountry, hiddenAreaCodes } = this.state;
    const { country, prefix } = this.props;

    if (value === '')
      return this.setState({ selectedCountry, formattedNumber: '' });

    const inputNumber = value.replace(/\D/g, '');
    let newSelectedCountry;
    let formattedNumber;

    // if new value start with selectedCountry.dialCode, format number, otherwise find newSelectedCountry
    if (
      selectedCountry &&
      startsWith(value, prefix + selectedCountry.dialCode)
    ) {
      formattedNumber = this.formatNumber(inputNumber, selectedCountry);
      this.setState({ formattedNumber });
    } else {
      if (this.props.disableCountryGuess) {
        newSelectedCountry = selectedCountry;
      } else {
        newSelectedCountry =
          this.guessSelectedCountry(
            inputNumber.substring(0, 6),
            country,
            onlyCountries,
            hiddenAreaCodes,
          ) || selectedCountry;
      }
      const dialCode =
        newSelectedCountry &&
        startsWith(inputNumber, prefix + newSelectedCountry.dialCode)
          ? newSelectedCountry.dialCode
          : '';

      formattedNumber = this.formatNumber(
        (this.props.disableCountryCode ? '' : dialCode) + inputNumber,
        newSelectedCountry || undefined,
      );
      this.setState({ selectedCountry: newSelectedCountry, formattedNumber });
      // if (value.length < 2) setTimeout(this.cursorToEnd, 0);
    }
  }

  render() {
    const {
      onlyCountries,
      selectedCountry,
      showDropdown,
      formattedNumber,
      hiddenAreaCodes,
    } = this.state;
    const {
      disableDropdown,
      renderStringAsFlag,
      isValid,
      placeholder,
      disabled,
      inputProps,
      mod,
      size,
      borderWidth,
      radius,
      transition,
      isInvalid,
    } = this.props;

    let isValidValue;
    if (typeof isValid === 'boolean') {
      isValidValue = isValid;
    } else {
      const isValidProcessed = isValid(
        formattedNumber.replace(/\D/g, ''),
        selectedCountry,
        onlyCountries,
        hiddenAreaCodes,
      );
      if (typeof isValidProcessed === 'boolean') {
        isValidValue = isValidProcessed;
      } else {
        // typeof === 'string'
        isValidValue = false;
      }
    }

    return (
      <Root onKeyDown={this.handleKeydown}>
        <Input
          onChange={this.handleInput}
          onClick={this.handleInputClick}
          onDoubleClick={this.handleDoubleClick}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onCopy={this.handleInputCopy}
          value={formattedNumber}
          ref={el => (this.numberInputRef = el)}
          onKeyDown={this.handleInputKeyDown}
          placeholder={placeholder}
          disabled={disabled}
          type="tel"
          size={size}
          mod={mod}
          borderWidth={borderWidth}
          radius={radius}
          transition={transition}
          isInvalid={!isValidValue || isInvalid}
          {...inputProps}
        />

        <ButtonContainer
          className={cx({ open: showDropdown })}
          mod={mod}
          ref={el => (this.dropdownContainerRef = el)}
        >
          {renderStringAsFlag ? (
            <SelectedFlag>{renderStringAsFlag}</SelectedFlag>
          ) : (
            <SelectedFlag
              onClick={
                disableDropdown ? undefined : this.handleFlagDropdownClick
              }
              title={
                selectedCountry
                  ? `${selectedCountry.name}: + ${selectedCountry.dialCode}`
                  : ''
              }
            >
              <Flag className={selectedCountry && selectedCountry.iso2}>
                {!disableDropdown && (
                  <Arrow className={cx({ up: showDropdown })} />
                )}
              </Flag>
            </SelectedFlag>
          )}

          {showDropdown && this.getCountryDropdownList()}
        </ButtonContainer>
      </Root>
    );
  }
}

Phone.propTypes = {
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,

  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  excludeCountries: PropTypes.arrayOf(PropTypes.string),

  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchNotFound: PropTypes.string,
  flagsImagePath: PropTypes.string,
  disabled: PropTypes.bool,

  autoFormat: PropTypes.bool,

  enableAreaCodes: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  enableTerritories: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),

  disableCountryCode: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  enableLongNumbers: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  countryCodeEditable: PropTypes.bool,
  enableSearch: PropTypes.bool,
  disableSearchIcon: PropTypes.bool,
  disableInitialCountryGuess: PropTypes.bool,
  disableCountryGuess: PropTypes.bool,

  regions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),

  inputProps: PropTypes.object,
  localization: PropTypes.object,
  masks: PropTypes.object,
  areaCodes: PropTypes.object,

  preserveOrder: PropTypes.arrayOf(PropTypes.string),

  defaultMask: PropTypes.string,
  alwaysDefaultMask: PropTypes.bool,
  prefix: PropTypes.string,
  copyNumbersOnly: PropTypes.bool,
  renderStringAsFlag: PropTypes.string,
  autocompleteSearch: PropTypes.bool,
  jumpCursorToEnd: PropTypes.bool,
  priority: PropTypes.object,
  enableAreaCodeStretch: PropTypes.bool,
  enableClickOutside: PropTypes.bool,
  showDropdown: PropTypes.bool,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  isValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

  keys: PropTypes.objectOf(PropTypes.number),

  mod: PropTypes.string,
  size: PropTypes.string,
  borderWidth: PropTypes.string,
  radius: PropTypes.string,
  transition: PropTypes.string,
  shadow: PropTypes.string,
  isInvalid: PropTypes.bool,
};

Phone.defaultProps = {
  country: 'us',
  value: '',

  onlyCountries: [],
  preferredCountries: ['us', 'ca'],
  excludeCountries: [],

  placeholder: '1 (702) 123-4567',
  searchPlaceholder: 'search',
  searchNotFound: 'No entries to show',
  flagsImagePath: './flags.png',
  disabled: false,

  autoFormat: true,
  enableAreaCodes: false,
  enableTerritories: false,
  disableCountryCode: false,
  disableDropdown: false,
  enableLongNumbers: false,
  countryCodeEditable: true,
  enableSearch: false,
  disableSearchIcon: false,
  disableInitialCountryGuess: false,
  disableCountryGuess: false,

  regions: '',

  inputProps: {},
  localization: {},

  masks: null,
  priority: null,
  areaCodes: null,

  preserveOrder: [],

  defaultMask: '... ... ... ... ..', // prefix+dialCode+' '+defaultMask
  alwaysDefaultMask: false,
  prefix: '+',
  copyNumbersOnly: true,
  renderStringAsFlag: '',
  autocompleteSearch: false,
  jumpCursorToEnd: true,
  enableAreaCodeStretch: false,
  enableClickOutside: true,
  showDropdown: false,

  isValid: true, // (value, selectedCountry, onlyCountries, hiddenAreaCodes) => true | false | 'Message'

  onChange: null,
  onFocus: null,
  onBlur: null,
  onClick: null,
  onKeyDown: null,
  onEnterKeyPress: null, // null or function

  keys: {
    UP: 38,
    DOWN: 40,
    RIGHT: 39,
    LEFT: 37,
    ENTER: 13,
    ESC: 27,
    PLUS: 43,
    A: 65,
    Z: 90,
    SPACE: 32,
    TAB: 9,
  },

  mod: 'outline',
  size: 'md',
  borderWidth: 'xs',
  radius: 'sm',
  transition: 'fast',
  shadow: 'md',
  isInvalid: false,
};

export default Phone;
