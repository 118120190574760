import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';

import {
  Root,
  Dialog,
  Content,
  Title,
  Close,
  Body,
  Preloader,
  PreloaderBar,
} from './Modal.style';

import useLockBodyScroll from '../../hooks/useLockBodyScroll';

const Modal = ({
  onClose  = null,
  closeOnClickBackground = true,
  closeOnEsc = true,
  children,
  header = null,
  maxWidth = null,
  isLoading = false,
}) => {
  const rootEl = useRef(null);
  useLockBodyScroll();

  const handleMouseDismiss = e => {
    if (e.target === rootEl.current) onClose();
  };

  const handleEscDismiss = event => {
    if (event.keyCode === 27 && onClose) onClose();
  };

  useEffect(() => {
    if (!closeOnEsc) return undefined;
    window.addEventListener('keydown', handleEscDismiss);

    return () => {
      window.removeEventListener('keydown', handleEscDismiss);
    };
  });

  return (
    <Portal>
      <Root
        tabIndex={-1}
        role="dialog"
        aria-modal
        ref={rootEl}
        onClick={onClose && closeOnClickBackground ? handleMouseDismiss : null}
      >
        <Dialog $maxWidth={maxWidth}>
          <Content>
            {isLoading && (
              <Preloader>
                <PreloaderBar />
              </Preloader>
            )}

            {header && <Title>{header}</Title>}

            {onClose && (
              <Close type="button" onClick={onClose}>
                <svg
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" />
                </svg>
              </Close>
            )}

            <Body>{children}</Body>
          </Content>
        </Dialog>
      </Root>
    </Portal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  closeOnClickBackground: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  children: PropTypes.node.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxWidth: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Modal;
