import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const FileItemComponent = ({ className, theme, filePath, ...rest }) => (
  <div className={className} {...rest}>
    <div className="FileItem__img">
      <img src={filePath} alt="" />
    </div>

    <div className="FileItem__controls">
      <button className="FileItem__btn">
        <svg
          className="FileItem__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
          />
        </svg>
      </button>
      <button className="FileItem__btn">
        <svg
          className="FileItem__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M352.201 425.775l-79.196 79.196c-9.373 9.373-24.568 9.373-33.941 0l-79.196-79.196c-15.119-15.119-4.411-40.971 16.971-40.97h51.162L228 284H127.196v51.162c0 21.382-25.851 32.09-40.971 16.971L7.029 272.937c-9.373-9.373-9.373-24.569 0-33.941L86.225 159.8c15.119-15.119 40.971-4.411 40.971 16.971V228H228V127.196h-51.23c-21.382 0-32.09-25.851-16.971-40.971l79.196-79.196c9.373-9.373 24.568-9.373 33.941 0l79.196 79.196c15.119 15.119 4.411 40.971-16.971 40.971h-51.162V228h100.804v-51.162c0-21.382 25.851-32.09 40.97-16.971l79.196 79.196c9.373 9.373 9.373 24.569 0 33.941L425.773 352.2c-15.119 15.119-40.971 4.411-40.97-16.971V284H284v100.804h51.23c21.382 0 32.09 25.851 16.971 40.971z"
          />
        </svg>
      </button>
      <button className="FileItem__btn">
        <svg
          className="FileItem__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
          />
        </svg>
      </button>
    </div>
  </div>
);

const FileItem = styled(FileItemComponent)`
  width: 125px;
  height: 163px;
  min-width: 125px;
  border: 1px solid ${p => p.theme.primary};
  overflow: hidden;

  .FileItem__img {
    position: relative;
    display: block;
    width: 125px;
    height: 125px;
    text-align: center;

    img {
      display: inline-block;
      height: auto;
      width: auto;
      max-width: 125px;
      max-height: 125px;
      vertical-align: middle;
    }
  }

  .FileItem__controls {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${p => p.theme.primary};
  }

  .FileItem__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 0;
    color: ${p => p.theme.primary};
    background-color: transparent;
    font-size: 15px;
    border: 0;
    cursor: pointer;
  }

  .FileItem__icon {
    height: 1em;
  }
`;

FileItem.displayName = 'FileItem';

FileItem.propTypes = {
  filePath: PropTypes.string.isRequired,
};

FileItem.defaultProps = {
  theme: defaultTheme,
};

export default FileItem;
