import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { light as defaultTheme } from '../../theme';
import { size, margin, padding, getVariant } from '../../utils';

const badgeStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
  line-height: 1;
  border-color: transparent;
  border-style: solid;
  white-space: nowrap;
`;

const Badge = styled('span').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['radius'].includes(prop) && defaultValidatorFn(prop),
})`
  ${badgeStyles}
  width: ${p => (p.isFullWidth ? '100%' : 'auto')};
  min-width: ${p => p.theme.sizes[p.size]};
  height: ${p => p.theme.sizes[p.size]};

  padding-right: ${p =>
    p.size === 'xs' ? p.theme.spaces[1] : p.theme.spaces[2]};
  padding-left: ${p =>
    p.size === 'xs' ? p.theme.spaces[1] : p.theme.spaces[2]};

  ${p =>
    p.mod === 'solid' &&
    css`
      color: #ffffff;
      background-color: ${getVariant(p.theme, p.variant, 500)};
      border-color: ${getVariant(p.theme, p.variant, 500)};
    `}
  ${p =>
    p.mod === 'outline' &&
    css`
      color: ${getVariant(p.theme, p.variant, 500)};
      border-color: ${getVariant(p.theme, p.variant, 500)};
    `}
  ${p =>
    p.mod === 'smooth' &&
    css`
      color: ${getVariant(p.theme, p.variant, 400)};
      background-color: ${getVariant(p.theme, p.variant, 100)};
      border-color: ${getVariant(p.theme, p.variant, 100)};
    `}

  font-size: ${p =>
    (p.size === 'xs' && 10) ||
    (p.size === 'sm' && 12) ||
    (p.size === 'md' && 14) ||
    (p.size === 'lg' && 14) ||
    (p.size === 'xl' && 18)}px;
  font-weight: ${p => p.size === 'xs' && 700};
  text-transform: ${p => p.size === 'xs' && 'uppercase'};
  border-width: ${p => p.theme.badge.borderWidth[p.borderWidth]};
  border-radius: ${p => p.theme.badge.radius[p.radius]};

  ${size}
  ${margin}
  ${padding}
`;

Badge.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    PropTypes.string,
  ]),
  mod: PropTypes.oneOf(['solid', 'outline', 'smooth', PropTypes.string]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', PropTypes.string]),
  borderWidth: PropTypes.oneOf([
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    PropTypes.string,
  ]),
  radius: PropTypes.oneOf([
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'full',
    PropTypes.string,
  ]),
  isFullWidth: PropTypes.bool,
};

Badge.defaultProps = {
  theme: defaultTheme,
  variant: 'primary',
  mod: 'solid',
  size: 'xs',
  borderWidth: 'xs',
  radius: 'sm',
  isFullWidth: false,
};

export default Badge;
