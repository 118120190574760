import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { light as defaultTheme } from '../../theme';
import { size, margin, padding, ring, getVariant } from '../../utils';

const buttonStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
  line-height: 1;
  vertical-align: middle;
  border-color: transparent;
  border-style: solid;
  user-select: none;
  white-space: nowrap;
  appearance: none;
  outline: none;

  &::-moz-focus-inner {
    border: none;
  }
`;

const Button = styled('button')
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['radius'].includes(prop) && defaultValidatorFn(prop),
  })
  .attrs(p => ({
    type: p.to || p.href ? null : p.type,
  }))`
  ${buttonStyles}
  min-width: ${p => p.theme.sizes[p.size]};
  height: ${p => p.theme.sizes[p.size]};

  padding-right: ${p =>
    p.size === 'xs' ? p.theme.spaces[1] : p.theme.spaces[2]};
  padding-left: ${p =>
    p.size === 'xs' ? p.theme.spaces[1] : p.theme.spaces[2]};

  ${p =>
    p.mod === 'solid' &&
    css`
      color: #ffffff;
      background-color: ${getVariant(p.theme, p.variant, 500)};
      border-color: ${getVariant(p.theme, p.variant, 500)};
    `}
  ${p =>
    p.mod === 'outline' &&
    css`
      color: ${getVariant(p.theme, p.variant, 500)};
      border-color: ${getVariant(p.theme, p.variant, 500)};
    `}
  ${p =>
    p.mod === 'smooth' &&
    css`
      color: ${getVariant(p.theme, p.variant, 700)};
      background-color: ${getVariant(p.theme, p.variant, 100)};
      border-color: ${getVariant(p.theme, p.variant, 100)};
    `}
  ${p =>
    p.mod === 'link' &&
    css`
      color: ${getVariant(p.theme, p.variant, 500)};
    `}

  font-size: ${p =>
    (p.size === 'xs' && 8) ||
    (p.size === 'sm' && 12) ||
    (p.size === 'md' && 14) ||
    (p.size === 'lg' && 14) ||
    (p.size === 'xl' && 18)}px;
  font-weight: ${p => p.size === 'xs' && 700};
  text-transform: ${p => p.size === 'xs' && 'uppercase'};
  border-width: ${p => p.theme.button.borderWidth[p.borderWidth]};
  border-radius: ${p => p.theme.button.radius[p.radius]};
  transition: all ${p => p.theme.transitions[p.transition]};

  &:not([disabled]):hover,
  &:not([disabled]):active {
    ${p =>
      p.mod === 'solid' &&
      css`
        background-color: ${getVariant(p.theme, p.variant, 600)};
        border-color: ${getVariant(p.theme, p.variant, 600)};
      `}
    ${p =>
      p.mod === 'outline' &&
      css`
        color: #ffffff;
        background-color: ${getVariant(p.theme, p.variant, 500)};
        border-color: ${getVariant(p.theme, p.variant, 500)};
      `}
    ${p =>
      p.mod === 'smooth' &&
      css`
        background-color: ${getVariant(p.theme, p.variant, 200)};
        border-color: ${getVariant(p.theme, p.variant, 200)};
      `}
    ${p =>
      p.mod === 'link' &&
      css`
        text-decoration: underline;
      `}
    cursor: pointer;
  }

  &:not([disabled]):focus {
    box-shadow: ${p =>
      !p.withoutOutline &&
      ring({
        width: '3px',
        color: getVariant(p.theme, p.variant, 500),
      })};
  }

  &[disabled] {
    opacity: 0.6;
    cursor: auto;
  }

  ${size}
  ${margin}
  ${padding}
`;

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
  ]),
  mod: PropTypes.oneOf(['solid', 'outline', 'smooth', 'link']),
  size: PropTypes.string,
  borderWidth: PropTypes.string,
  radius: PropTypes.string,
  transition: PropTypes.string,
  withoutOutline: PropTypes.bool,
};

Button.defaultProps = {
  theme: defaultTheme,
  type: 'button',
  variant: 'primary',
  mod: 'solid',
  size: 'md',
  borderWidth: 'xs',
  radius: 'sm',
  transition: 'fast',
  withoutOutline: false,
};

export default Button;
