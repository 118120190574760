import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import * as defaultTheme from '../../../variables';

const isRequired = css`
  &::after {
    margin-left: 2px;
    color: ${p => p.theme.colors.red[500]};
    content: '*';
  }
`;

const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  padding: ${p => p.theme.spaces[1]} 0;
  color: ${p => p.theme.label.fg};
  font-size: ${p => p.theme.label.fontSize};
  font-weight: ${p => p.theme.label.fontWeight};

  ${p => p.isRequired && isRequired};
`;

const Hidden = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

Label.Hidden = Hidden;

Label.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

Label.defaultProps = {
  theme: defaultTheme,
};

export default Label;
