import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const PanelBody = styled.div`
  padding: ${p => p.theme.space * 2}px;
  color: ${p => p.theme.panelBodyFg};
  background-color: ${p => p.theme.panelBodyBg};
  border-top: 1px solid ${p => p.theme.panelBodyBorder};

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 5px;
  }
`;

PanelBody.displayName = 'PanelBody';

PanelBody.defaultProps = {
  theme: defaultTheme,
};

export default PanelBody;
