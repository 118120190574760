import React from 'react';
import Input from '../Input/Input';
// TODO: add toFixed prop
// TODO: strip $ sign.
// eslint-disable-next-line react/prop-types
const NumberInput = ({ value, onChange, ...props }) => {
  return (
    <Input
      value={String(value)}
      onChange={e => {
        const {
          target: { name, value: val },
        } = e;
        onChange({
          target: {
            name,
            value: val,
            // val === '' || Number.isNaN(Number(val)) || val.endsWith('.')
            //   ? val
            //   : Number(val),
          },
        });
      }}
      {...props}
    />
  );
};

export default NumberInput;
