import assign from 'lodash/assign';

const createParser = config => {
  const parse = props => {
    const styles = {};

    Object.keys(props).forEach(key => {
      if (!config[key]) return;
      const sx = config[key];
      const raw = props[key];

      assign(styles, sx(raw, props));
    });

    return styles;
  };

  parse.propNames = Object.keys(config);

  const keys = Object.keys(config);
  if (keys.length > 1) {
    keys.forEach(key => {
      parse[key] = createParser({ [key]: config[key] });
    });
  }

  return parse;
};

const createStyleFunction = ({ properties, property }) => {
  const props = properties || [property];
  const sx = value => {
    if (value === null) return undefined;
    const result = {};

    props.forEach(prop => {
      result[prop] = value;
    });

    return result;
  };

  return sx;
};

export const create = (args = {}) => {
  const config = {};

  Object.keys(args).forEach(key => {
    config[key] = createStyleFunction(args[key]);
  });

  const parser = createParser(config);
  return parser;
};

export const flexbox = create({
  display: { property: 'display' },
  flexDirection: { property: 'flexDirection' },
  flexWrap: { property: 'flexWrap' },
  justifyContent: { property: 'justifyContent' },
  alignItems: { property: 'alignItems' },
  alignContent: { property: 'alignContent' },
  order: { property: 'order' },
  flexGrow: { property: 'flexGrow' },
  flexShrink: { property: 'flexShrink' },
  flexBasis: { property: 'flexBasis' },
  alignSelf: { property: 'alignSelf' },
});

export const grid = create({
  display: { property: 'display' },
  gridGap: { property: 'grid-gap' },
  gridRowGap: { property: 'grid-row-gap' },
  gridColumnGap: { property: 'grid-column-gap' },
  gridColumn: { property: 'grid-column' },
  gridRow: { property: 'grid-row' },
  gridArea: { property: 'grid-area' },
  gridAutoFlow: { property: 'grid-auto-flow' },
  gridAutoRows: { property: 'grid-auto-rows' },
  gridAutoColumns: { property: 'grid-auto-columns' },
  gridTemplateRows: { property: 'grid-template-rows' },
  gridTemplateColumns: { property: 'grid-template-columns' },
  gridTemplateAreas: { property: 'grid-template-areas' },
});

export const size = create({
  w: { property: 'width' },
  h: { property: 'height' },
  minWidth: { property: 'minWidth' },
  maxWidth: { property: 'maxWidth' },
  maxHeight: { property: 'maxHeight' },
  minHeight: { property: 'minHeight' },
});

export const margin = create({
  m: { property: 'margin' },
  mt: { property: 'marginTop' },
  mr: { property: 'marginRight' },
  mb: { property: 'marginBottom' },
  ml: { property: 'marginLeft' },
  mx: { properties: ['marginLeft', 'marginRight'] },
  my: { properties: ['marginTop', 'marginBottom'] },
});

export const padding = create({
  p: { property: 'padding' },
  pt: { property: 'paddingTop' },
  pr: { property: 'paddingRight' },
  pb: { property: 'paddingBottom' },
  pl: { property: 'paddingLeft' },
  px: { properties: ['paddingLeft', 'paddingRight'] },
  py: { properties: ['paddingTop', 'paddingBottom'] },
});

export const typography = create({
  fontFamily: { property: 'font-family' },
  ff: { property: 'font-family' },
  fontSize: { property: 'font-size' },
  fz: { property: 'font-size' },
  fontWeight: { property: 'font-weight' },
  fw: { property: 'font-weight' },
  lineHeight: { property: 'line-height' },
  lh: { property: 'line-height' },
  letterSpacing: { property: 'letterSpacing' },
  ls: { property: 'letterSpacing' },
  textAlign: { property: 'text-align' },
  ta: { property: 'text-align' },
  fontStyle: { property: 'font-style' },
  fs: { property: 'font-style' },
  textTransform: { property: 'text-transform' },
  tt: { property: 'text-transform' },
});
