import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const bgRightToLeft = keyframes`
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
`;

export const bgLeftToRight = keyframes`
  from {
    background-position: -1rem 0;
  }

  to {
    background-position: 0 0;
  }
`;

export const aniHorizontal = keyframes`
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
`;

export const slideInRight = keyframes`
  from {
      transform: translate3d(110%, 0, 0);
      visibility: visible;
  }
  to {
      transform: translate3d(0, 0, 0);
  }
`;

export const slideInLeft = keyframes`
  from {
      transform: translate3d(-110%, 0, 0);
      visibility: visible;
  }
  to {
      transform: translate3d(0, 0, 0);
  }
`;

export const slideInUp = keyframes`
  from {
      transform: translate3d(0, 110%, 0);
      visibility: visible;
  }
  to {
      transform: translate3d(0, 0, 0);
  }
`;

export const slideInDown = keyframes`
  from {
      transform: translate3d(0, -110%, 0);
      visibility: visible;
  }
  to {
      transform: translate3d(0, 0, 0);
  }
`;

export const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;
