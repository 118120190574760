import styled from 'styled-components';
import config, { breakpointKeys } from '../config';

const Container = styled('div')`
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${config.gutterWidth / 2}px;
  padding-left: ${config.gutterWidth / 2}px;

  ${breakpointKeys.map(x => {
    const withoutGutter = config.container[x] - config.gutterWidth;

    return `
      @media (min-width: ${config.container[x]}px) {
        width: ${withoutGutter}px;
      }
    `;
  })};
`;

Container.displayName = 'Container';

export default Container;
