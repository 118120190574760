import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const Panel = styled.div`
  color: ${p => p.theme.panelFg};
  background-color: ${p => p.theme.panelBg};
`;

Panel.displayName = 'Panel';

Panel.defaultProps = {
  theme: defaultTheme,
};

export default Panel;
