import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const FileInputComponent = ({ className, theme, isLoading, ...rest }) => (
  <div className={className}>
    <input className="FileInput__input" type="file" {...rest} />
    <svg
      className="FileInput__loading"
      width="38"
      height="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  </div>
);

const FileInput = styled(FileInputComponent)`
  position: relative;
  display: inline-block;
  width: 125px;
  height: 125px;
  min-width: 125px;
  background-color: ${p => p.theme.fileInputBg};
  border: 1px solid ${p => p.theme.primary};

  &::before,
  &::after {
    position: absolute;
    display: ${p => (p.isLoading ? 'none' : 'block')};
    content: '';
    top: 50%;
    left: 50%;
    width: 36px;
    height: 1px;
    background-color: ${p => p.theme.variants.primary[300]};
    margin-left: -18px;
    transition: background-color ${p => p.theme.transitionFast} ease-in-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: ${p => p.theme.variants.primary[600]};
    }
  }

  .FileInput__input {
    position: absolute;
    display: ${p => p.isLoading && 'none'};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  .FileInput__loading {
    position: absolute;
    display: ${p => !p.isLoading && 'none'};
    top: 50%;
    left: 50%;
    stroke: ${p => p.theme.primary};
    transform: translate(-50%, -50%);
  }
`;

FileInput.displayName = 'FileInput';

FileInput.propTypes = {
  isLoading: PropTypes.bool,
};

FileInput.defaultProps = {
  theme: defaultTheme,
  isLoading: false,
};

export default FileInput;
