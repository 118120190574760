import fetch from 'isomorphic-fetch';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { apiUri } from '../configPublic';
import EventHub from './events/EventHub';

let jwt = null;
let jwtExpires = null;
let apolloClient = null;

export const getJwt = () => jwt;

export const setJwt = token => {
  // console.info({ token });
  jwt = token;
  if (token) {
    const decoded = jwtDecode(jwt);
    jwtExpires = decoded.exp * 1000;
  } else {
    jwtExpires = null;
  }
};

export const isJwtExpired = () => {
  if (jwt == null) {
    return false;
  }
  return Date.now() >= jwtExpires;
};

export const setApolloClient = client => {
  apolloClient = client;
};

export const isBrowser = () => typeof window !== 'undefined';

export const setUserToStorage = user =>
  Promise.resolve().then(() => {
    if (!isBrowser()) {
      return;
    }
    // EventHub.dispatch('setUser', user);
    if (user) {
      window.localStorage.setItem(
        'user',
        JSON.stringify({
          attributes: user,
          created: new Date().getTime().toString(),
        })
      );
    } else {
      window.localStorage.removeItem('user');
    }
  });

export const getUserFromStorage = () => {
  if (!isBrowser() || !window.localStorage.getItem('user')) {
    return null;
  }
  const userStorage = JSON.parse(window.localStorage.getItem('user'));
  // TODO: inspect "created" property and if older than specified period, delete local storage
  return userStorage.attributes;
};

export const setUserPropertyToStorage = (property, value) =>
  Promise.resolve().then(() => {
    if (!isBrowser()) {
      return;
    }
    const user = getUserFromStorage();
    if (user) {
      user[property] = value;
      setUserToStorage(user);
    }
  });

export const isLoggedIn = () => {
  // const user = getUserFromStorage();
  const loggedIn = Cookies.get('loggedIn');
  return loggedIn != null;
};

export const signUp = userInput =>
  fetch(`//${apiUri}/v1/auth/register`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInput),
  }).then(response =>
    response.json().then(result => {
      if (!response.ok) {
        throw Error(JSON.stringify(result));
      }
      return result;
    })
  );

export const verify = ({ token, captcha }) =>
  fetch(`//${apiUri}/v1/auth/verify`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, captcha }),
  }).then(response =>
    response.json().then(result => {
      if (!response.ok) {
        throw Error(JSON.stringify(result));
      }
      setJwt(result.authTokens.jwt.token);
      setUserToStorage(result.user);
      EventHub.dispatch({ event: 'auth', action: 'signIn', data: result.user });
      return result;
    })
  );

export const signIn = userInput =>
  fetch(`//${apiUri}/v1/auth/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInput),
  })
    .then(response =>
      response.json().then(results => {
        if (!response.ok) {
          throw Error(JSON.stringify(results));
        }
        return results;
      })
    )
    .then(result => {
      setJwt(result.authTokens.jwt.token);
      setUserToStorage(result.user);
      EventHub.dispatch({ event: 'auth', action: 'signIn', data: result.user });
      return result;
    });

export const signOut = () =>
  fetch(`//${apiUri}/v1/auth/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (!response.ok) {
        throw Error(response);
      }
      return response.json();
    })
    .finally(result => {
      if (apolloClient) {
        apolloClient.resetStore();
      }
      setJwt(null);
      setUserToStorage(null);
      EventHub.dispatch({ event: 'auth', action: 'signOut' });
      return result;
    });

export const getRefreshFetchRequest = (returnUser = false) =>
  fetch(`https://${apiUri}/v1/auth/refresh`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: returnUser ? JSON.stringify({ returnUser }) : null,
  })
    .then(response =>
      response.json().then(result => {
        if (!response.ok || result.errorType != null) {
          throw Error(JSON.stringify(result));
        }
        setJwt((result || {}).token);
        return result;
      })
    )
    .catch(async err => {
      console.info(err);
      let errorObj;
      try {
        errorObj = JSON.parse(err.message);
      } catch (e) {
        errorObj = {};
      }
      if (
        errorObj.type !== 'TOKEN_ERR_INVALID_REFRESH_TOKEN' &&
        errorObj.type !== 'NOT_FOUND'
      ) {
        throw err;
      }
      return signOut();
    });

export const forgotPassword = userInput =>
  fetch(`//${apiUri}/v1/auth/forgot-password`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInput),
  }).then(response =>
    response.json().then(result => {
      if (!response.ok) {
        throw Error(JSON.stringify(result));
      }
      return result;
    })
  );

export const resetPassword = ({ token, password, captcha }) =>
  fetch(`//${apiUri}/v1/auth/reset-password`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, password, captcha }),
  }).then(response =>
    response.json().then(result => {
      if (!response.ok) {
        throw Error(JSON.stringify(result));
      }
      return result;
    })
  );

export const currentAuthenticatedUser = () =>
  isLoggedIn()
    ? getRefreshFetchRequest(true).then(result => {
        console.info({ currentAuthenticatedUserResponse: result });
        const { user } = result || {};
        if (user != null) {
          setUserToStorage(user);
          EventHub.dispatch({
            event: 'auth',
            action: 'currentAuthenticatedUser',
            data: user,
          });
          return user;
        }
        return null;
      })
    : Promise.resolve(null);
