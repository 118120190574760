import { createContext, useState, useEffect } from 'react';
import { darkTheme, lightTheme } from '../components/theme';

export const ThemeContext = createContext();

export const useTheme = () => {
  const storedTheme =
    typeof window !== 'undefined' && window.localStorage.getItem('theme');
  const [theme, setTheme] = useState(storedTheme || 'light');

  const toggleTheme = () =>
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('theme', theme);
      document.documentElement.setAttribute('data-theme', theme);
    }
  }, [theme]);

  return [theme, toggleTheme];
};

export const getTheme = theme => (theme === 'light' ? lightTheme : darkTheme);
