import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';

import { ring, useTheme } from '../../utils';

export const customStyles = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
    width: '100%',
  }),
  control: (
    provided,
    {
      isFocused,
      isDisabled,
      selectProps: { theme, size, mod, borderWidth, radius, transition },
    },
  ) => {
    const styles = {
      minHeight: theme.sizes[size],
      backgroundColor: theme.input[mod].bg,
      borderColor: theme.input[mod].borderColor,
      fontSize:
        (size === 'xs' && 10) ||
        (size === 'sm' && 12) ||
        (size === 'md' && 14) ||
        (size === 'lg' && 14) ||
        (size === 'xl' && 18),
      borderWidth: theme.input.borderWidth[borderWidth],
      borderRadius: theme.input.radius[radius],
      transition: `all ${theme.transitions[transition]}`,

      // iPhone and iOS Form Input Zoom Fixes
      '@media screen and (device-aspect-ratio: 2/3)': {
        fontSize: '16px',
      },
      '@media screen and (device-aspect-ratio: 40/71)': {
        fontSize: '16px',
      },
      '@media screen and (device-aspect-ratio: 375/667)': {
        fontSize: '16px',
      },
      '@media screen and (device-aspect-ratio: 9/16)': {
        fontSize: '16px',
      },
    };
    const focus = isFocused && {
      outline: 'none',
      boxShadow: ring({
        width: theme.ringWidth,
        color: theme.input[mod].ringColor,
      }),
    };
    const disabled = isDisabled && {
      opacity: 0.6,
      cursor: 'auto',
    };
    return {
      ...provided,
      ...styles,
      ...focus,
      ...disabled,
    };
  },
  placeholder: (provided, { selectProps: { theme, mod } }) => ({
    ...provided,
    color: theme.input[mod].placeholder,
  }),
  singleValue: (provided, { selectProps: { theme, mod } }) => ({
    ...provided,
    color: theme.input[mod].fg,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '100%',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  }),
  indicatorSeparator: (provided, { selectProps: { theme, mod } }) => ({
    ...provided,
    backgroundColor: theme.input[mod].arrowColor,
  }),
  dropdownIndicator: (
    provided,
    { selectProps: { theme, mod, menuIsOpen } },
  ) => ({
    ...provided,
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '100%',
    padding: 0,
    color: theme.input[mod].arrowColor,
    transform: menuIsOpen && 'rotate(180deg)',
  }),
  menu: (
    provided,
    { selectProps: { theme, popupRadius, shadow, isMobile } },
  ) => {
    const styles = {
      color: theme.popup.fg,
      backgroundColor: theme.popup.bg,
      borderRadius: theme.radius[popupRadius],
      boxShadow: theme.shadows[shadow],
    };
    const mobile = isMobile && {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      margin: 0,
      borderRadius: 0,
      boxShadow: 'none',
    };
    return {
      ...provided,
      ...styles,
      ...mobile,
    };
  },
  menuList: (provided, { selectProps: { theme, isMobile } }) => ({
    ...provided,
    padding: isMobile ? 0 : theme.spaces[1],
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 8000,
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
  groupHeading: (provided, { selectProps: { theme, size, mod } }) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    height: theme.sizes[size],
    color: theme.input[mod].fg,
    fontWeight: 500,
    textTransform: 'none',
  }),
  option: (
    provided,
    {
      isFocused,
      isSelected,
      isDisabled,
      selectProps: { theme, centerOptions },
    },
  ) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: centerOptions && 'center',
    height: '29px',
    borderRadius: theme.radius.sm,
    opacity: isDisabled && 0.6,
    backgroundColor: (isFocused || isSelected) && theme.popup.active,
  }),
  multiValue: (provided, { selectProps: { theme } }) => ({
    ...provided,
    backgroundColor: theme.popup.active,
    borderRadius: theme.radius.xs,
  }),
  multiValueLabel: (provided, { selectProps: { theme } }) => ({
    ...provided,
    color: theme.popup.fg,
    borderRadius: 0,
  }),
  multiValueRemove: (provided, { selectProps: { theme } }) => ({
    ...provided,
    color: theme.popup.fg,
    borderRadius: 0,

    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer',
    },
  }),
};

export const Autocomplete = ({
  isMobile = false,
  mod = 'outline',
  size = 'md',
  borderWidth = 'xs',
  radius = 'sm',
  transition = 'fast',
  popupRadius = 'lg',
  shadow = 'md',
  menuPortalTarget = typeof window !== 'undefined' ? document.body : null,
  menuPosition = 'fixed',
  menuPlacement = 'auto',
  menuShouldScrollIntoView = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Select
      theme={theme}
      styles={customStyles}
      isMobile={isMobile}
      mod={mod}
      size={size}
      borderWidth={borderWidth}
      radius={radius}
      transition={transition}
      popupRadius={popupRadius}
      shadow={shadow}
      menuPortalTarget={menuPortalTarget}
      menuPosition={menuPosition}
      menuPlacement={menuPlacement}
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      {...props}
    />
  );
};

export const AutocompleteAsync = ({
  isMobile = false,
  mod = 'outline',
  size = 'md',
  borderWidth = 'xs',
  radius = 'sm',
  transition = 'fast',
  popupRadius = 'lg',
  shadow = 'md',
  menuPortalTarget = typeof window !== 'undefined' ? document.body : null,
  menuPosition = 'fixed',
  menuPlacement = 'auto',
  menuShouldScrollIntoView = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Async
      theme={theme}
      styles={customStyles}
      isMobile={isMobile}
      mod={mod}
      size={size}
      borderWidth={borderWidth}
      radius={radius}
      transition={transition}
      popupRadius={popupRadius}
      shadow={shadow}
      menuPortalTarget={menuPortalTarget}
      menuPosition={menuPosition}
      menuPlacement={menuPlacement}
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      {...props}
    />
  );
};

export const AutocompleteAsyncCreatable = ({
  isMobile = false,
  mod = 'outline',
  size = 'md',
  borderWidth = 'xs',
  radius = 'sm',
  transition = 'fast',
  popupRadius = 'lg',
  shadow = 'md',
  menuPortalTarget = typeof window !== 'undefined' ? document.body : null,
  menuPosition = 'fixed',
  menuPlacement = 'auto',
  menuShouldScrollIntoView = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <AsyncCreatable
      theme={theme}
      styles={customStyles}
      isMobile={isMobile}
      mod={mod}
      size={size}
      borderWidth={borderWidth}
      radius={radius}
      transition={transition}
      popupRadius={popupRadius}
      shadow={shadow}
      menuPortalTarget={menuPortalTarget}
      menuPosition={menuPosition}
      menuPlacement={menuPlacement}
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      {...props}
    />
  );
};

const propTypes = {
  isMobile: PropTypes.bool,
  mod: PropTypes.string,
  size: PropTypes.string,
  borderWidth: PropTypes.string,
  radius: PropTypes.string,
  transition: PropTypes.string,
  popupRadius: PropTypes.string,
  shadow: PropTypes.string,
  menuPortalTarget: PropTypes.instanceOf(
    typeof Element === 'undefined' ? () => {} : Element,
  ),
  menuPosition: PropTypes.oneOf(['fixed', 'absolute']),
  menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
  menuShouldScrollIntoView: PropTypes.bool,
};

Autocomplete.propTypes = propTypes;
AutocompleteAsync.propTypes = propTypes;
AutocompleteAsyncCreatable.propTypes = propTypes;
