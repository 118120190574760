import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer, cssTransition, toast, Flip } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { Helmet } from 'react-helmet';

import client from '../graphql/apolloClient';
import RuntimeProvider from '../context/RuntimeContext';
import ModalProvider from '../context/ModalContext';
import UserProvider from '../context/UserContext';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { ThemeContext, useTheme, getTheme } from '../context/ThemeContext';
import GlobalStyle from './globalStyles';

import appleIconSrc from '../images/favicons/apple-touch-icon.png';
import favicon16x16 from '../images/favicons/favicon-16x16.png';
import favicon32x32 from '../images/favicons/favicon-32x32.png';
import favicon64x64 from '../images/favicons/favicon-64x64.png';

const faviconLinks = [
  { rel: 'apple-touch-icon', href: appleIconSrc },
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16x16 },
  { rel: 'icon', type: 'image/png', sizes: '32x32', href: favicon32x32 },
  { rel: 'shortcut icon', type: 'image/png', href: favicon64x64 },
];

// const CustomTransition = cssTransition({
//   // enter: 'Toastify--animate Toastify__slide-enter',
//   exit: 'Toastify--animate Toastify__fade-exit',
//   appendPosition: true,
//   collapseDuration: 200,
// });

const App = ({ children }) => {
  const mountedRef = useRef();
  const onlineStatus = useOnlineStatus();
  const [theme, toggleTheme] = useTheme();
  const currentTheme = getTheme(theme);

  if (typeof document !== 'undefined') {
    injectStyle();
  }

  useEffect(() => {
    if (!mountedRef.current) return;
    const networkSuccess = 'Your internet connection was restored.';
    const networkError =
      'Internet connectivity issue detected. Please check your network connection.';

    if (onlineStatus) {
      if (toast.isActive('networkError')) toast.dismiss('networkError');
      if (toast.isActive('networkSuccess')) toast.update('networkSuccess');
      else toast.success(networkSuccess, { toastId: 'networkSuccess' });
    } else {
      if (toast.isActive('networkSuccess')) toast.dismiss('networkSuccess');
      if (toast.isActive('networkError')) toast.update('networkError');
      else toast.error(networkError, { toastId: 'networkError' });
    }
  }, [onlineStatus]);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <ThemeProvider theme={currentTheme}>
          <ModalProvider>
            <UserProvider>
              <RuntimeProvider>
                <Helmet link={faviconLinks} />
                <GlobalStyle />
                <DndProvider backend={HTML5Backend}>{children}</DndProvider>
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  pauseOnHover
                  pauseOnFocusLoss
                  newestOnTop
                  closeOnClick
                  draggable
                  transition={Flip}
                  style={{ zIndex: 99999 }}
                />
              </RuntimeProvider>
            </UserProvider>
          </ModalProvider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </ApolloProvider>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
