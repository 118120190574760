import { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import mergeWith from 'lodash/mergeWith';

import { light as defaultTheme } from '../theme';

const get = (from, path) => {
  const paths = String(path).split('.');
  const pathsLength = paths.length;
  let result = from;
  for (let i = 0; i < pathsLength; i += 1) {
    if (result === undefined) return result;
    const newPath = paths[i];
    result = !isNil(result[newPath]) ? result[newPath] : undefined;
  }
  return result;
};

function cascade(value, arg) {
  if (typeof value === 'function') {
    return cascade(value(arg), arg);
  }
  return value;
}

const getThemeValue = (props, path, initial = props.theme) =>
  cascade(get(initial, path), props);

export const th = (path, defaultValue) => props => {
  const value = getThemeValue(props, path);
  if (!isNil(value)) return value;
  if (!isNil(defaultValue)) return defaultValue;
  return path;
};

export const useTheme = () => useContext(ThemeContext);

const createUseGetter = getter => (value, defaultValue) => {
  const theme = useTheme();
  return useMemo(() => getter(value, defaultValue)({ theme }), [
    value,
    defaultValue,
    theme,
  ]);
};

export const useTh = createUseGetter(th);

export function extendTheme(overrides, baseTheme = defaultTheme) {
  function customizer(source, override) {
    if (isFunction(source)) {
      return (...args) => {
        const sourceValue = source(...args);

        const overrideValue = isFunction(override)
          ? override(...args)
          : override;

        return mergeWith({}, sourceValue, overrideValue, customizer);
      };
    }

    return undefined;
  }

  return mergeWith({}, baseTheme, overrides, customizer);
}
