/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { globalHistory } from '@gatsbyjs/reach-router';

export const ModalContext = React.createContext({ modalQueue: [] });
export const ModalConsumer = ModalContext.Consumer;

class ModalProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setModalVar: (key, value) => {
        const obj = {};
        obj[key] = value;
        this.setState(prevState => {
          return { vars: { ...prevState.vars, ...obj } };
        });
      },
      clearModalQueue: () => {
        this.setState({ modalQueue: [] });
      },
      setModalQueue: queue => {
        const modalQueue = Array.isArray(queue) ? queue : [queue];
        this.setState({ modalQueue });
      },
      pushModalQueue: modal => {
        const normalizedModal = Array.isArray(modal) ? modal : [modal];
        this.setState(prevState => {
          return {
            modalQueue: [...(prevState.modalQueue || []), ...normalizedModal],
          };
        });
      },
      popModalQueue: () => {
        this.setState(prevState => {
          return {
            modalQueue: [...(prevState.modalQueue || []).slice(0, -1)],
          };
        });
      },
      modalQueue: [],
    };
  }

  componentDidMount() {
    this.historyUnsubscribe = globalHistory.listen(({ action }) => {
      if (action === 'POP') this.handleHistoryChange();
    });
  }

  componentWillUnmount() {
    this.historyUnsubscribe();
  }

  handleHistoryChange() {
    const { clearModalQueue, modalQueue } = this.state;
    if (modalQueue.length > 0) clearModalQueue();
  }

  render() {
    const { children } = this.props;

    return (
      <ModalContext.Provider value={this.state}>
        {children}
      </ModalContext.Provider>
    );
  }
}

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ModalProvider;
