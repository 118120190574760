/* eslint-disable react/prop-types */
import React from "react"
import { Button } from "@uplft/react-ui-kit"
import { toast } from "react-toastify"

import App from "./src/components/App"
import { FlexBox } from "./src/components/SharedComponents"

import "./src/styles/global.css"
import "./src/styles/stylesSimpleBar.css"

const SwMsg = ({ closeToast }) => (
  <>
    <p>
      This application has been updated.
      <br />
      Reload to display the latest version?
    </p>
    <FlexBox justifyContent="space-between">
      <Button size="sm" variant="secondary" onClick={closeToast}>
        Cancel
      </Button>
      <Button size="sm" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </FlexBox>
  </>
)

export const wrapRootElement = ({ element }) => <App>{element}</App>

export const onServiceWorkerUpdateReady = () => {
  // eslint-disable-next-line no-alert
  // const answer = window.confirm(
  //   `This application has been updated. ` +
  //     `Reload to display the latest version?`,
  // );
  // if (answer === true) {
  //   window.location.reload();
  // }

  if (toast.isActive("sw")) return

  toast(<SwMsg />, {
    toastId: "sw",
    autoClose: false,
  })
}

// export const shouldUpdateScroll = ({ pathname }) => {
//   // ({ prevRouterProps, pathname })
//   if (pathname === '/') {
//     return false;
//   }
//   return true;
// };
