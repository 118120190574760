import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ring } from '../../utils';

const Root = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  appearance: none;
`;

const Track = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: ${p => p.theme.switch.width};
  height: ${p => p.theme.switch.height};
  background-color: ${p => p.theme.switch.bg};
  border-color: ${p => p.theme.switch.border};
  border-style: solid;
  border-width: 1px;
  border-radius: 9999px;
  transition: all ${p => p.theme.transitions.fast} ease-in-out;
  cursor: pointer;
`;

const Control = styled.span`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(${p => p.theme.switch.height} - 6px);
  height: calc(${p => p.theme.switch.height} - 6px);
  background-color: ${p => p.theme.switch.control};
  border-radius: 9999px;
  transition: all ${p => p.theme.transitions.fast} ease-in-out;
`;

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;

  &[disabled] + ${Track} {
    opacity: 0.6;
    cursor: auto;
  }

  &:checked + ${Track} {
    background-color: ${p => p.theme.switch.bgChecked};
    border-color: ${p => p.theme.switch.borderChecked};

    ${Control} {
      left: calc(100% - 2px);
      background-color: ${p => p.theme.switch.controlChecked};
      transform: translateX(-100%);
    }
  }

  &:not([disabled]):active + ${Track} ${Control} {
    width: 18px;
  }

  &:not([disabled]):focus + ${Track} {
    box-shadow: ${p =>
      ring({
        width: p.theme.ringWidth,
        color: p.theme.switch.ringColor,
      })};
  }
`;

export const Switch = ({ name, ...props }) => {
  return (
    <Root>
      <StyledInput type="checkbox" name={name} {...props} />
      <Track>
        <Control />
      </Track>
    </Root>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Switch;
