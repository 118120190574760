import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const RuntimeContext = React.createContext();

class RuntimeProvider extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      setRuntimeVar: (key, value) => {
        const obj = {};
        obj[key] = value;
        this.setState(prevState => {
          return { vars: { ...prevState.vars, ...obj } };
        });
      },
      vars: {
        // define default runtime values here...
      },
    };
  }

  render() {
    const { vars, ...rest } = this.state;
    const { children } = this.props;

    return (
      <RuntimeContext.Provider value={{ ...vars, ...rest }}>
        {children}
      </RuntimeContext.Provider>
    );
  }
}

export default RuntimeProvider;
