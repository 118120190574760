import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { light as defaultTheme } from '../../theme';
import { bgRightToLeft } from '../../animations';
import { size, margin, padding, getVariant } from '../../utils';

const animation = css`
  animation: ${bgRightToLeft} 1s linear infinite;
`;

const Root = styled.div`
  display: flex;
  width: 100%;
  height: 20px;
  background-color: ${(p) => p.theme.progress.bg};
  font-size: 12px;
  border-radius: ${(p) => p.theme.radius.sm};
  overflow: hidden;
  ${size}
  ${margin}
  ${padding}
`;

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(p) => p.percent}%;
  color: #ffffff;
  background-color: ${(p) => getVariant(p.theme, p.variant, 500)};
  background-image: ${(p) =>
    p.striped &&
    'linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)'};
  background-size: ${(p) => p.striped && '1rem 1rem'};
  text-align: center;
  transition: width ${(p) => p.theme.transitions.slowest} ease;
  ${(p) => p.animated && animation};
`;

const Progress = ({
  theme = defaultTheme,
  percent = 0,
  variant = 'primary',
  striped = false,
  animated = false,
  children = null,
  ...rest
}) => {
  return (
    <Root
      theme={theme}
      percent={percent}
      variant={variant}
      striped={striped}
      animated={animated}
      {...rest}
    >
      <Bar
        role="progressbar"
        percent={percent}
        variant={variant}
        striped={striped}
        animated={animated}
      >
        {children}
      </Bar>
    </Root>
  );
};

Progress.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  percent: PropTypes.number.isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
  ]),
  striped: PropTypes.bool,
  animated: PropTypes.bool,
  children: PropTypes.node,
};

export default Progress;
