export const hexToRgba = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const ring = ({
  inset = '',
  width = '3px',
  color,
  offsetWidth = '0px',
  offsetColor = 'transparent',
  opacity = 0.5,
}) => {
  const offsetShadow = `${inset} 0 0 0 ${offsetWidth} ${offsetColor}`;
  const shadow = `${inset} 0 0 0 calc(${width} + ${offsetWidth}) ${hexToRgba(
    color,
    opacity,
  )}`;

  return `${offsetShadow}, ${shadow}`;
};

export const getVariant = (theme, variant, value) =>
  theme.variants[variant]?.[value] || theme.variants.primary[value];

function toVal(mix) {
  let str = '';

  if (typeof mix === 'string' || typeof mix === 'number') {
    str += mix;
  } else if (typeof mix === 'object') {
    if (Array.isArray(mix)) {
      for (let k = 0; k < mix.length; k += 1) {
        if (mix[k]) {
          const y = toVal(mix[k]);
          if (y) {
            if (str) str += ' ';
            str += y;
          }
        }
      }
    } else {
      Object.keys(mix).forEach(key => {
        if (mix[key]) {
          if (str) str += ' ';
          str += key;
        }
      });
    }
  }

  return str;
}

export const cx = (...args) => {
  let str = '';

  for (let i = 0; i < 9; i += 1) {
    if (args[i]) {
      const x = toVal(args[i]);
      if (x) {
        if (str) str += ' ';
        str += x;
      }
    }
  }

  return str;
};
