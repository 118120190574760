import PropTypes from 'prop-types';
import styled from 'styled-components';
import config, { breakpointKeys } from '../config';

const offsetKeys = breakpointKeys.map(d => `${d}Offset`);

const Col = styled('div')`
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 100%;
  padding-right: ${config.gutterWidth / 2}px;
  padding-left: ${config.gutterWidth / 2}px;

  ${p => p.reverse && `flex-direction: column-reverse;`};

  ${p =>
    Object.keys(p)
      .filter(k => breakpointKeys.indexOf(k) >= 0)
      .sort((k1, k2) => breakpointKeys.indexOf(k1) - breakpointKeys.indexOf(k2))
      .map(k => {
        let val;

        if (Number.isInteger(p[k])) {
          val = `
            flex-basis: ${(100 / config.gridSize) * p[k]}%;
            max-width: ${(100 / config.gridSize) * p[k]}%;
            display: block;
          `;
        } else if (p[k]) {
          val = `
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
            display: block;
          `;
        } else {
          val = `
            display: none;
          `;
        }

        return config.media[k]`${val}`;
      })};

  ${p =>
    Object.keys(p)
      .filter(k => offsetKeys.indexOf(k) >= 0)
      .map(
        k =>
          config.media[k.replace(/Offset$/, '')]`margin-left: ${(100 /
            config.gridSize) *
            p[k]}%;`,
      )};
`;

Col.displayName = 'Col';

Col.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number,
  reverse: PropTypes.bool,
};

export default Col;
