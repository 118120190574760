import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { light as defaultTheme } from '../../theme';
import { size, margin, ring } from '../../utils';

const selectStyles = css`
  display: block;
  position: relative;
  background-color: transparent;
  font-size: inherit;
  font-weight: 700;
  border-color: transparent;
  border-style: solid;
  appearance: none;
  white-space: nowrap;
`;

const Root = styled('select').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['radius'].includes(prop) && defaultValidatorFn(prop),
})`
  ${selectStyles}
  width: ${p => (p.isFullWidth ? '100%' : 'auto')};
  min-width: ${p => p.theme.sizes[p.size]};
  height: ${p => p.theme.sizes[p.size]};
  line-height: ${p => p.theme.sizes[p.size]};
  padding-right: ${p => p.theme.spaces[4]};
  padding-left: ${p => (p.size === 'xs' ? p.theme.spaces[1] : '12px')};
  color: ${p => p.theme.input[p.mod].fg};
  background-color: ${p => p.theme.input[p.mod].bg};
  border-color: ${p =>
    p.isInvalid
      ? p.theme.input[p.mod].errorBorderColor
      : p.theme.input[p.mod].borderColor};
  font-size: ${p =>
    (p.size === 'xs' && 10) ||
    (p.size === 'sm' && 12) ||
    (p.size === 'md' && 13) ||
    (p.size === 'lg' && 14) ||
    (p.size === 'xl' && 18)}px;
  border-width: ${p => p.theme.input.borderWidth[p.borderWidth]};
  border-radius: ${p => p.theme.input.radius[p.radius]};
  transition: all ${p => p.theme.transitions[p.transition]};

  &:not([disabled]):focus {
    box-shadow: ${p =>
      p.ring &&
      ring({
        width: p.theme.ringWidth,
        color: p.theme.input[p.mod].ringColor,
      })};
  }

  &[disabled] {
    opacity: 0.6;
    cursor: auto;
  }

  &[readonly] {
    cursor: default;
    pointer-events: none;
  }

  &[multiple] {
    height: auto;
    padding: ${p => p.theme.spaces[1]};
  }

  &:not([multiple]) {
    background-image: ${p =>
      `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20'%3E%3Cpath fill='${p.theme.input[
        p.mod
      ].arrowColor.replace(
        /#/g,
        '%23',
      )}' d='M10.001 10.952 6.824 7.774a.6.6 0 0 0-.847.85l3.6 3.6a.599.599 0 0 0 .824.018l3.622-3.615a.6.6 0 0 0-.847-.85l-3.175 3.175Z'/%3E%3C/svg%3E")`};
    background-repeat: no-repeat;
    background-position-x: calc(100% - 6px);
    background-position-y: center;
    background-size: 20px;
  }

  &::placeholder {
    color: ${p => p.theme.input[p.mod].placeholder};
  }

  /*** iPhone and iOS Form Input Zoom Fixes ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    font-size: 16px;
  }

  ${size}
  ${margin}
`;

const Select = forwardRef(({ theme, options, ...props }, ref) => {
  const toString = value =>
    typeof value !== 'string' ? JSON.stringify(value) : value;

  const getOption = option => {
    const value = toString(option.value);
    return (
      <option
        key={`${option.label}-${value}`}
        value={value}
        disabled={option.disabled}
      >
        {option.label}
      </option>
    );
  };

  const selectOptions = options.map(groupOrOption => {
    if (groupOrOption.options) {
      const groupedOptions = groupOrOption.options.map(option =>
        getOption(option),
      );
      return (
        <optgroup key={groupOrOption.label} label={groupOrOption.label}>
          {groupedOptions}
        </optgroup>
      );
    }
    return getOption(groupOrOption);
  });

  return (
    <Root ref={ref} {...props}>
      {selectOptions}
    </Root>
  );
});

Select.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  mod: PropTypes.string,
  size: PropTypes.string,
  borderWidth: PropTypes.string,
  radius: PropTypes.string,
  transition: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
  ring: PropTypes.bool,
};

Select.defaultProps = {
  theme: defaultTheme,
  mod: 'outline',
  size: 'md',
  borderWidth: 'xs',
  radius: 'sm',
  transition: 'fast',
  isFullWidth: true,
  isInvalid: false,
  ring: false,
};

export default Select;
