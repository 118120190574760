import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../../variables';

const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: ${p => p.theme.spaces[2]};
  border-collapse: collapse;

  & > thead {
    @media (${p => p.theme.xsMax}) {
      display: ${p => p.stacked && 'none'};
    }
  }

  & > thead > tr {
    background-color: ${p => p.bordered && !p.striped && p.theme.table.bgAlt};
  }

  & > tbody > tr {
    @media (${p => p.theme.xsMax}) {
      display: ${p => p.stacked && 'block'};
    }
  }

  & > tbody > tr:nth-of-type(odd) {
    background-color: ${p => p.striped && p.theme.table.bgAlt};

    @media (${p => p.theme.xsMax}) {
      background-color: ${p => p.stacked && p.theme.table.bgAlt};
    }
  }

  & > tbody > tr:hover {
    background-color: ${p => p.hovered && p.theme.table.bgAlt};
  }

  & > thead > tr > th,
  & > tbody > tr > td {
    padding: 10px 8px;
    border: ${p => p.bordered && `1px solid ${p.theme.table.borderColor}`};
    vertical-align: middle;
  }

  & > thead > tr > th {
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid ${p => p.theme.table.borderColor};
  }

  & > tbody > tr > td {
    border-top: 1px solid ${p => p.theme.table.borderColor};

    @media (${p => p.theme.xsMax}) {
      display: ${p => p.stacked && 'block'};
      border-top: ${p => p.stacked && 'none'};
    }
  }

  & > tbody > tr:first-child > td,
  & > tbody > tr:last-child > td {
    border-bottom: ${p =>
      p.striped && `1px solid ${p.theme.table.borderColor}`};
  }
`;

Table.displayName = 'Table';

Table.propTypes = {
  bordered: PropTypes.bool,
  striped: PropTypes.bool,
  hovered: PropTypes.bool,
  stacked: PropTypes.bool,
};

Table.defaultProps = {
  theme: defaultTheme,
  bordered: false,
  striped: false,
  hovered: false,
  stacked: false,
};

export default Table;
