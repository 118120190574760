import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { create } from 'lodash';

import { AutocompleteAsyncCreatable } from '../AutocompleteAsync/AutocompleteAsync';

const AutocompleteAsyncCreatableWithReverseLookup = ({
  value = null,
  reverseLookup = null,
  onCreateOption = null,
  handleChange = null,
  isMulti = undefined,
  ...props
}) => {
  const [inputValue, setInputValue] = useState();
  const [isLoading, setIsLoaoding] = useState(false);
  useEffect(() => {
    if (typeof reverseLookup === 'function') {
      setIsLoaoding(true);
      reverseLookup(value)
        .then((reverseLookupValue) => setInputValue(reverseLookupValue))
        .finally(() => {
          setIsLoaoding(false);
        });
    } else {
      console.info({ value });
      setInputValue((value || []).map((itm) => ({ label: itm, value: itm })));
    }
  }, [value]);

  return (
    <AutocompleteAsyncCreatable
      value={inputValue}
      isLoading={isLoading}
      isMulti={isMulti}
      onCreateOption={async (createValue) => {
        if (typeof onCreateOption !== 'function') {
          const newVal = isMulti
            ? [...(value || []), createValue]
            : createValue;
          console.info({ newVal });
          // setInputValue(newVal);
          handleChange({ target: { name: props.name, value: newVal } });
          return;
        }
        const val = await onCreateOption(createValue);
        // console.info({val, name: props.name})
        setInputValue(val);
        handleChange({ target: { name: props.name, value: val.value } });
      }}
      {...props}
    />
  );
};

AutocompleteAsyncCreatableWithReverseLookup.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  reverseLookup: PropTypes.func,
  onCreateOption: PropTypes.func,
};

export default AutocompleteAsyncCreatableWithReverseLookup;
