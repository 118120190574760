import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Portal } from 'react-portal';

const Root = styled.div`
  position: fixed;
  top: ${p =>
    (p.position === 'topLeft' && p.offset) ||
    (p.position === 'topRight' && p.offset) ||
    (p.position === 'topCenter' && p.offset)};
  right: ${p =>
    (p.position === 'topRight' && p.offset) ||
    (p.position === 'bottomRight' && p.offset)};
  bottom: ${p =>
    (p.position === 'bottomLeft' && p.offset) ||
    (p.position === 'bottomRight' && p.offset) ||
    (p.position === 'bottomCenter' && p.offset)};
  left: ${p =>
    (p.position === 'topLeft' && p.offset) ||
    (p.position === 'topCenter' && '50%') ||
    (p.position === 'bottomLeft' && p.offset) ||
    (p.position === 'bottomCenter' && '50%')};
  width: ${p => p.width};
  transform: ${p =>
    (p.position === 'topCenter' && 'translate(-50%, 0%)') ||
    (p.position === 'bottomCenter' && 'translate(-50%, 0%)')};
  z-index: ${p => p.zindex};
`;

const Notifications = ({ children, ...rest }) => {
  return (
    <Portal>
      <Root {...rest}>{children}</Root>
    </Portal>
  );
};

Notifications.displayName = 'Notifications';

Notifications.propTypes = {
  position: PropTypes.oneOf([
    'topLeft',
    'topRight',
    'topCenter',
    'bottomLeft',
    'bottomRight',
    'bottomCenter',
  ]),
  width: PropTypes.string,
  offset: PropTypes.string,
  zindex: PropTypes.number,
  children: PropTypes.node,
};

Notifications.defaultProps = {
  position: 'bottomRight',
  width: 'auto',
  offset: '24px',
  zindex: 9999,
  children: null,
};

export default Notifications;
