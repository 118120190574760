import styled from 'styled-components';
import * as defaultTheme from '../../../variables';

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${p => p.theme.space * 6}px;
  padding-right: ${p => p.theme.space * 2}px;
  padding-left: ${p => p.theme.space * 2}px;
  color: ${p => p.theme.panelHeaderFg};
  background-color: ${p => p.theme.panelHeaderBg};
  border-top: 2px solid ${p => p.theme.panelHeaderBorder};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

PanelHeader.displayName = 'PanelHeader';

PanelHeader.defaultProps = {
  theme: defaultTheme,
};

export default PanelHeader;
