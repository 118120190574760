import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { DateTimeStyled, TimeInputStyled } from './DateTime.style';

const DateTime = ({
  mod = 'outline',
  size = 'md',
  borderWidth = 'xs',
  radius = 'sm',
  radiusPopup = 'lg',
  transition = 'fast',
  isInvalid = false,
  customDayStyles = null,
  autoComplete = 'off',
  dateFormat = 'yyyy-MM-dd',
  ...rest
}) => {
  const CustomTimeInput = ({ value, onChange }) => {
    return (
      <TimeInputStyled
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  };

  return (
    <DateTimeStyled
      mod={mod}
      size={size}
      borderWidth={borderWidth}
      radius={radius}
      radiusPopup={radiusPopup}
      transition={transition}
      isInvalid={isInvalid}
      $dayStyles={customDayStyles}
    >
      <DatePicker
        className="DateTime__input"
        calendarClassName="DateTime__calendar"
        dateFormat={dateFormat}
        autoComplete={autoComplete}
        customTimeInput={<CustomTimeInput />}
        {...rest}
      />
    </DateTimeStyled>
  );
};

DateTime.propTypes = {
  mod: PropTypes.string,
  size: PropTypes.string,
  borderWidth: PropTypes.string,
  radius: PropTypes.string,
  radiusPopup: PropTypes.string,
  transition: PropTypes.string,
  isInvalid: PropTypes.bool,
  autoComplete: PropTypes.string,
  dateFormat: PropTypes.string,
};

export default DateTime;
