import styled from 'styled-components';
import { flexbox, size, margin, padding } from '@uplft/react-ui-kit';

export const FlexBox = styled.div`
  display: flex;
  ${flexbox}
  ${size}
  ${margin}
  ${padding}
`;

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 33px 22px 40px;
    background-color: ${p => p.theme.main.bg.mobile};
  }

  @media (min-width: 1024px) {
    padding: 26px 50px 50px;
    background-color: ${p => p.theme.main.bg.desktop};
  }
`;

export const Row = styled.div`
  margin-right: -8px;
  margin-bottom: 16px;
  margin-left: -8px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Col = styled.div`
  width: 100%;
  margin-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;

  @media (min-width: 768px) {
    width: ${p => p.proportion && `calc(${100 / p.proportion}%)`};
    margin-bottom: 0;
  }
`;

export const ColorIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  color: #fff;
  background-color: ${p => (p.$color ? p.$color : p.theme.primary)};
  font-size: 17px;
  border-radius: 4px;
`;

export const BorderedItem = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 16px;
  padding-right: 16px;

  &:not(:last-of-type) {
    border-right: 1px solid ${p => p.theme.borderColor};
  }

  span {
    color: #78909c;
    font-size: 13px;
  }
`;

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #8f95a5;
  font-size: 16px;
  border-radius: 20px;

  &:hover {
    background-color: ${p =>
      p.theme.colorScheme === 'light' ? '#fafafc' : '#222433'};
  }

  * {
    pointer-events: none;
  }
`;

export const Search = styled.input`
  height: 32px;
  padding: 0 16px 0 42px;
  background-color: #fafafc;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 24 24'%3E%3Cpath fill='%23c0c2c7' d='M11.111 4.999a6.122 6.122 0 1 0 0 12.244 6.122 6.122 0 0 0 0-12.244Zm8.116 6.118a8.076 8.076 0 0 1-1.711 4.983l3.189 3.19a1 1 0 0 1-1.414 1.414l-3.188-3.189a8.112 8.112 0 1 1 3.124-6.398Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: 15px 7px;
  font-size: 14px;
  letter-spacing: 0.14px;
  border: none;
  border-radius: 36px;

  &::placeholder {
    color: #8f95a5;
  }
`;

export const ButtonShadow = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  color: ${p => (p.theme.colorScheme === 'light' ? '#586179' : '#fff')};
  background-color: ${p =>
    p.theme.colorScheme === 'light' ? '#fff' : '#292b3c'};
  font-weight: 700;
  border-radius: 4px;

  @media (max-width: 1023px) {
    width: 100%;
    height: 40px;
    font-size: 12px;
    letter-spacing: 0.3px;
    border-width: 1px;
    border-style: solid;
    border-color: ${p =>
      p.theme.colorScheme === 'light' ? '#e2e3ea' : '#4e515e'};
  }

  @media (min-width: 1024px) {
    width: max-content;
    height: 35px;
    font-size: 13px;
    letter-spacing: 0.33px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.03);
  }

  svg {
    margin-left: 6px;
    color: ${p => (p.theme.colorScheme === 'light' ? '#596177' : '#676c7d')};
  }
`;

export const ButtonLikeField = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  padding-left: 12px;
  padding-right: 8px;
  color: ${p => (p.theme.colorScheme === 'light' ? '#586179' : '#fff')};
  background-color: transparent;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid
    ${p => (p.theme.colorScheme === 'light' ? '#e2e3e9' : '#4e515e')};
  border-radius: 4px;

  svg {
    color: ${p => (p.theme.colorScheme === 'light' ? '#586179' : '#fff')};
  }
`;

export const TabsList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const TabsItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${p =>
    (p.theme.colorScheme === 'light' && !p.$isActive && '#8f95a5') ||
    (p.theme.colorScheme === 'dark' && !p.$isActive && '#676c7d') ||
    (p.$isActive && '#5279f7')};
  font-family: inherit;
  font-weight: 700;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${p =>
    (p.theme.colorScheme === 'light' && !p.$isActive && '#e8e9ec') ||
    (p.theme.colorScheme === 'dark' && !p.$isActive && '#4e515e') ||
    (p.$isActive && '#5279f7')};
  cursor: pointer;

  @media (max-width: 1023px) {
    min-width: 50px;
    height: 26px;
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  @media (min-width: 1024px) {
    min-width: 108px;
    height: 38px;
    font-size: 16px;
    letter-spacing: 0.4px;
  }
`;
