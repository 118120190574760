export const env = process.env.GATSBY_APP_ENV || 'develop';

export const apiUri = process.env.GATSBY_API_DOMAIN || 'localhost:3000';

export const frontendUri =
  process.env.GATSBY_FRONTEND_DOMAIN || 'localhost:8000';

export const uploadsUri =
  process.env.GATSBY_UPLOADS_DOMAIN || 'uploads.develop.uplft.bi';

export const reCaptchaKey = '6LdeGjcaAAAAALfC0a1zxKvcwQKVNFS_NOM8-LA0';

export const inactiveUserTimeThreshold = 1000 * 60 * 5; // 5 minutes in milliseconds

export const isRegister = true;
export const isSocialAuth = false;
